import { Link as RouterLink } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import BasePage from './containers/BasePage';
import ComponentMetadata from './containers/ComponentMetadata';
import ContentLibrary from './containers/ContentLibrary';
import MasterData from './containers/MasterData';
import PlatformUsersLandingPage from './containers/PlatformLevelUsers/LandingPage';
import ContentAssignmentPage from './containers/ContentAssignmentPage';
import ReportPage from './containers/ReportPage';
import ModifyCoursePage from './containers/ModifyCoursePage';
import CreateProgramPage from './containers/CreateProgramPage';
import {
  ROUTE_HOME, ROUTE_UPLOAD, MODIFY_COURSES,
  ROUTE_REPORT, PROGRAM_CREATION, ROUTE_CONTENT_ASSIGNMENT, permissionGroup,
} from './constants';

// Add if you want to show tabs on header
export const headerTabs = [
  {
    component: RouterLink,
    path: `/${ROUTE_HOME}`,
    title: 'Program',
    value: 'home',
    permission: permissionGroup.ALL,
  },
  {
    component: RouterLink,
    path: '/contentLibrary/lti/journals',
    title: 'Content Library',
    value: 'contentLibrary',
    permission: permissionGroup.GROUP2,
  },
  {
    component: RouterLink,
    path: '/componentMetadata',
    title: 'Component Metadata',
    value: 'componentMetadata',
    permission: permissionGroup.ADMIN,
    activityLog: true,
  },
  {
    component: RouterLink,
    path: '/master_data/client',
    title: 'Master Data',
    value: 'master_data',
    permission: permissionGroup.ALL,
  },
  {
    component: RouterLink,
    path: '/users',
    title: 'Users',
    value: 'users',
    permission: permissionGroup.ALL,
    activityLog: true,
  },
];
// Add Routes
export const Routes = [
  {
    component: BasePage,
    path: `/${ROUTE_HOME}`,
    title: 'Program',
    value: 'home',
    permission: permissionGroup.ALL,
  },
  {
    component: ComponentMetadata,
    path: '/componentMetadata',
    title: 'Component Metadata',
    value: 'componentMetadata',
    permission: permissionGroup.ADMIN,
  },
  {
    component: ContentLibrary,
    path: '/contentLibrary/:programSubType/:feature',
    title: 'Content Library',
    value: 'contentLibrary',
    permission: permissionGroup.GROUP2,
  },
  {
    component: ContentLibrary,
    path: '/contentLibrary/:programSubType/:feature/:id',
    title: 'Content Library',
    value: 'contentLibrary',
    permission: permissionGroup.GROUP2,
  },
  {
    component: MasterData,
    path: '/master_data/:programSubType',
    title: 'Master Data',
    value: 'master_data',
    permission: permissionGroup.ALL,
  },
  {
    component: PlatformUsersLandingPage,
    path: '/users',
    title: 'Users',
    value: 'users',
    permission: permissionGroup.ALL,
  },
  {
    component: PlatformUsersLandingPage,
    path: '/users/:programSubType',
    title: 'Users',
    value: 'users',
    permission: permissionGroup.ALL,
  },
  {
    component: ContentAssignmentPage,
    path: `/${ROUTE_REPORT}/:programId/${PROGRAM_CREATION}/:transactionId/${ROUTE_CONTENT_ASSIGNMENT}/:courseId`,
    title: 'Report',
    value: 'report',
    permission: permissionGroup.GROUP2,
  },
  {
    component: ReportPage,
    path: `/${ROUTE_REPORT}/:programId/:programSubType`,
    title: 'Report',
    value: 'report',
    permission: permissionGroup.ALL,
  },
  {
    component: ReportPage,
    path: `/${ROUTE_REPORT}/:programId/:programSubType/:transactionId`,
    title: 'Report',
    value: 'report',
    permission: permissionGroup.ALL,
  },
  {
    component: ReportPage,
    path: `/${ROUTE_REPORT}/:programId/:programSubType/:transactionId/:feature`,
    title: 'Report',
    value: 'report',
    permission: permissionGroup.ALL,
  },
  {
    component: CreateProgramPage,
    path: `/${ROUTE_UPLOAD}/:programSubType/`,
    title: 'CreateProgramPage',
    value: 'CreateProgramPage',
    permission: permissionGroup.GROUP2,
  },
  {
    component: ModifyCoursePage,
    path: `/${ROUTE_UPLOAD}/:programSubType/${MODIFY_COURSES}`,
    title: 'Upload',
    value: 'upload',
    permission: permissionGroup.GROUP2,
  },
];
