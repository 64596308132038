/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _map from 'lodash/map';
import _has from 'lodash/has';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import CustomSelect from '../common/CustomSelect';
import LinkButton from '../common/LinkButton';
import {
  BUILD_TYPE_OPTIONS,
  SOURCE_BUILD_STATUS_OPTIONS,
  YES_NO_OPTIONS,
  ELUCIDAT_PROJECT_TYP_OPTIONS,
  CLIENT_SET_UP_OPTIONS,
  LP_LANGUAGES_OPTIONS, AUTO_HIDE_DURATION,
} from '../../constants';
import AutoMultiSelect from '../common/AutoMultiSelect';
import { getProgramsBySearch } from '../../containers/BasePage/apis';
import { ContentDetailsSection } from '../ContentManagement/ContentDetailsSection';
import { useStyles } from '../../stylesheets/commonModal.styles';
import FilterSection from './FilterSection';
import AsyncSelectClient from '../common/AsyncSelectClient';
import ErrorText from '../common/ErrorText';
import BuildTypeWrapper from './BuildTypeWrapper';
import FilterInfoToast from '../LandingPage/FilterInfoToast';
import DeleteIcon from '../../assets/icons/trash_icon.svg';
import EditIcon from '../../assets/icons/edit_icon.svg';
import { getErrorMessage, getErrorType } from '../../helpers/apiHelper';

const drawerStyles = makeStyles({
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  globalLogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    overflow: 'auto',
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
  },
  mainWrapper: {
    height: 'calc(100vh - 21rem)',
  },
  logHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2rem 0rem',
  },
  linkDecoration: {
    textDecoration: 'none',
  },
  centerAction: {
    margin: 'auto',
    width: '55%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyBox: {
    height: '1rem',
  },
  summaryTitle: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  titleDirection: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  summaryHelperText: {
    paddingLeft: '0.25rem',
  },
  iconBtn: {
    '&.MuiIconButton-root': {
      padding: '12px 0 12px 12px',
    },
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'white',
    },
  },
});

const getClientLabel = (program) => (
  <Typography variant="h3" component="span">
    {`${program.client_id} - ${program.client_name}`}
  </Typography>
);

const SummaryNode = ({ primary, helper = '', direction }) => {
  const classes = drawerStyles();

  return (
    <Box className={`${classes.summaryTitle} ${direction === 'column' && classes.titleDirection}`}>
      <Typography variant="subtitle1">{primary}</Typography>
      {helper?.length > 0 && (
        <Typography variant="subtitle2" component="span" className={classes.summaryHelperText}>{helper}</Typography>
      )}
    </Box>
  );
};

const LpFiltersDrawer = ({
  closeFilterDrawer, values, handleChange, setFieldValue, resetForm, handleSubmit, stageOptions,
  errors, getFilterGroups, popoverData, setPopoverData, selectedFilterGroup,
  setSelectedFilterGroup, deleteFilterGroup, touched, clearAllFilters, setPayloadIntoForm,
}) => {
  const closeIconStyle = useStyles();
  const selectRef = useRef(null);
  const headerRef = useRef(null);
  const classes = drawerStyles();

  const [parentConfigIdExpand, setParentConfigIdExpand] = useState(false);
  const [configIdExpand, setConfigIdExpand] = useState(false);
  const [savedFilterOptions, setSavedFilterOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deletedFilterName, setDeletedFilterName] = useState('');
  const [configIdKey, setConfigIdKey] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const getValueList = (list) => (list || []);
  const selectedBuildStatus = getValueList(values?.source_build_status);
  const selectedClientSetup = getValueList(values?.client_setup);
  const selectedLanguages = getValueList(values?.language_codes);
  const selectedStatus = getValueList(values?.status);

  const isFilterName = values?.enableCreate || values?.enableEdit;

  const checkBuildType = values?.build_type?.length > 0 && values?.build_type !== 'Source';

  const modalFilterName = values?.filter_name || selectedFilterGroup?.label || deletedFilterName;

  const primaryCTA = values?.enableCreate ? 'Save & Apply' : 'Apply Filter';

  const checkConfigId = configIdExpand && values?.source_program_ids?.length > 0;

  const handleAutoCompleteResult = (result) => {
    const lp = result?.data?.data.map((data) => ({
      value: data.id,
      label: data.config_id,
    }));
    return lp;
  };

  const handlePickedClientsClone = (items) => {
    setFieldValue('client_ids', _map(items));
  };

  const handlePickedParentConfigId = (items) => {
    if (items?.length < values?.source_program_ids?.length
      || !items) {
      setFieldValue('program_ids', null);
    }
    setFieldValue('source_program_ids', items);
    setConfigIdKey(configIdKey + 1);
  };

  const handlePickedConfigId = (items) => {
    setFieldValue('program_ids', items);
  };

  const getSavedFilters = useCallback(async () => {
    try {
      const resp = await getFilterGroups();
      if (resp.success === true) {
        const filterArray = resp.data || [];
        const savedFilterList = _map(filterArray, (obj) => ({
          id: obj?.id,
          role: obj?.role,
          label: obj?.name,
          value: obj?.payload,
        }));
        setSavedFilterOptions(savedFilterList);
      }
    } catch (err) {
      setPopoverData({
        type: getErrorType(err),
        message: getErrorMessage(err),
      });
    }
  }, [getFilterGroups]);

  const clearFilterDropdown = () => {
    setSelectedFilterGroup({});
    if (selectRef.current) {
      selectRef.current.select.clearValue();
    }
  };

  const onCancel = useCallback(() => {
    clearFilterDropdown();
    clearAllFilters();
    closeFilterDrawer();
  }, [resetForm]);

  const ShowErrorToast = () => {
    setAnchorEl(headerRef.current);
  };

  useEffect(() => {
    if (popoverData?.type?.length > 0) {
      ShowErrorToast();
      if (popoverData?.type === 'delete_success') {
        setDeletedFilterName(selectedFilterGroup.label);
        clearFilterDropdown();
        getSavedFilters();
      }
    } else {
      setAnchorEl(null);
    }
  }, [popoverData]);

  useEffect(() => {
    getSavedFilters();
  }, [getSavedFilters]);

  const setFilterFormValues = useCallback((elem) => {
    setSelectedFilterGroup(elem);
    if (elem?.value !== undefined) {
      const data = elem?.value;
      setPayloadIntoForm(data);
    }
  }, [setPayloadIntoForm]);

  const closePopover = () => {
    if (popoverData?.type === 'delete_success') {
      setDeletedFilterName('');
    }
    setPopoverData({});
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl !== null && !['duplicate_settings', 'delete_confirm'].includes(popoverData?.type)) {
      setTimeout(() => {
        setAnchorEl(null);
        setPopoverData({});
      }, AUTO_HIDE_DURATION);
    }
  }, [anchorEl]);

  const moveToDuplcateFilter = useCallback(() => {
    setSelectedFilterGroup({
      ...popoverData.data,
      label: popoverData?.data?.name,
      value: popoverData?.data?.payload,
    });
    closePopover();
    setFieldValue('enableCreate', false);
    setFieldValue('filter_name', '');
  }, [popoverData]);

  const onEdit = () => {
    setFieldValue('enableEdit', true);
    setFieldValue('filter_name', selectedFilterGroup.label);
  };

  const confirmDelete = () => {
    setPopoverData({
      type: 'delete_confirm',
      message: '',
    });
  };

  const createFilter = () => {
    resetForm();
    clearFilterDropdown();
    setFieldValue('filter_name', '');
    setFieldValue('enableCreate', true);
  };

  return (
    <Box style={{ width: '40rem' }}>

      <Box
        boxShadow={3}
        style={{
          boxShadow: '0px 1px 10px 0px #051C2C1F',
        }}
        ref={headerRef}
      >
        <Container className={classes.globalLogWrapper}>
          <IconButton aria-label="close" className={`${classes.closeButton} ${closeIconStyle.closeButton}`} onClick={closeFilterDrawer}>
            <CloseIcon />
          </IconButton>
          <Box className={classes.logHeader}>
            <Typography variant="h1" style={{ padding: 0 }}>Filters</Typography>
            {!isFilterName && (
              <LinkButton variant="btnGeneral" className={classes.linkDecoration} text="Create Filter" onClick={() => createFilter()} />
            )}
          </Box>
          {
            isFilterName ? (
              <ContentDetailsSection
                name="filter_name"
                type="text"
                fieldType="text"
                placeholder="Type a name for the filter group"
                isEdit
                hasError={touched.filter_name && !!errors.filter_name}
                errorText={touched.filter_name && errors.filter_name}
                value={values?.filter_name}
                handleChange={handleChange}
                showTextLimit
                maxTextLimit="30"
              />
            ) : (
              <Box sx={{ display: 'flex' }}>
                <CustomSelect
                  selectRef={selectRef}
                  placeholder="Select a saved filter"
                  isOptionsNotLoading
                  defaultValue={savedFilterOptions?.find(
                    (el) => el?.label === selectedFilterGroup?.label,
                  )}
                  options={savedFilterOptions}
                  className={classes.dropdown}
                  labelClass={classes.labelClass}
                  onChange={setFilterFormValues}
                  noOptionsMessage="No Options Found"
                />
                {selectedFilterGroup?.value && (
                  <Box sx={{ padding: '0 1rem' }}>
                    <IconButton aria-label="edit" onClick={onEdit} className={classes.iconBtn} style={{ paddingLeft: '0.25rem' }}>
                      <img src={EditIcon} alt="edit" />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={confirmDelete} className={classes.iconBtn}>
                      <img src={DeleteIcon} alt="transh" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )
          }

        </Container>
      </Box>

      <Container className={`${classes.globalLogWrapper} ${classes.mainWrapper}`} sx={{ marginTop: '0.5rem' }}>
        <FilterSection
          onChange={(e, expanded) => {
            if (expanded) {
              setParentConfigIdExpand(expanded);
            }
          }}
          summary={<SummaryNode primary="Parent Program Config ID" helper={values?.source_program_ids?.length > 0 && (`(${values?.source_program_ids?.length})`)} />}
        >
          {parentConfigIdExpand ? (
            <AutoMultiSelect
              placeholder="Search parent program config ID(s)"
              selectedItems={values?.source_program_ids}
              fetchData={(searchText) => getProgramsBySearch(searchText, '')}
              maxLimit={5}
              limitExceedMsg="Looks like you have reached the maximum number of selections for the Learning Plan"
              handleMultiSelect={handleAutoCompleteResult}
              onChange={(val) => handlePickedParentConfigId(val)}
            />
          ) : null}
        </FilterSection>

        <FilterSection
          onChange={(e, expanded) => {
            setConfigIdExpand(expanded);
          }}
          summary={<SummaryNode primary="Config ID" helper={values?.program_ids?.length > 0 && (`(${values?.program_ids?.length})`)} />}
        >
          <Typography>Values are dependent upon selected parent program ID(s)</Typography>
          {checkConfigId ? (
            <AutoMultiSelect
              key={configIdKey}
              placeholder="Search config ID(s)"
              selectedItems={values?.program_ids}
              fetchData={(searchText) => getProgramsBySearch(searchText, _map(values.source_program_ids, 'value'))}
              maxLimit={5}
              limitExceedMsg="Looks like you have reached the maximum number of selections for the Learning Plan"
              handleMultiSelect={handleAutoCompleteResult}
              onChange={(val) => handlePickedConfigId(val)}
            />
          ) : (
            <Box style={{ marginTop: '1rem' }}>
              <ErrorText
                touchedField
                errorField="Please select parent program config ID(s) first"
              />
            </Box>
          )}
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Build Type" helper={values?.build_type && (`(${values?.build_type})`)} />}
        >
          <ContentDetailsSection
            name="build_type"
            type="text"
            fieldType="radio"
            radioGroupDirection={false}
            options={BUILD_TYPE_OPTIONS}
            isEdit
            value={values?.build_type}
            handleChange={(val) => {
              setFieldValue('build_type', val);
            }}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Source Build Status" helper={values?.source_build_status?.length > 0 && (`(${values?.source_build_status?.length})`)} />}
        >
          <BuildTypeWrapper
            isHavingBuildType={!!values?.build_type}
            buildType={values?.build_type}
            forSource
          >
            {values?.build_type === 'Source' ? (
              <ContentDetailsSection
                name="source_build_status"
                type="text"
                fieldType="checkbox"
                options={SOURCE_BUILD_STATUS_OPTIONS}
                radioGroupDirection={false}
                value={values.source_build_status}
                isEdit
                handleChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue('source_build_status', [...selectedBuildStatus, e.target.value]);
                  } else {
                    const list = values.source_build_status.filter((o) => o !== e.target.value);
                    setFieldValue('source_build_status', list);
                  }
                }}
              />
            ) : <Box className={classes.emptyBox} />}
          </BuildTypeWrapper>
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Replicable As-Is" helper={values?.replicable && (`(${values?.replicable})`)} />}
        >
          <BuildTypeWrapper
            isHavingBuildType={!!values?.build_type}
            buildType={values?.build_type}
            forSource
          >
            {values?.build_type === 'Source' ? (
              <ContentDetailsSection
                name="replicable"
                type="text"
                fieldType="radio"
                radioGroupDirection={false}
                options={YES_NO_OPTIONS}
                isEdit
                value={values?.replicable}
                handleChange={(val) => {
                  setFieldValue('replicable', val);
                }}
              />
            ) : <Box className={classes.emptyBox} />}
          </BuildTypeWrapper>
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Re-Usable (Immediate Enrollment Available)" helper={values?.re_usable && (`(${values?.re_usable})`)} />}
        >
          <BuildTypeWrapper
            isHavingBuildType={!!values?.build_type}
            buildType={values?.build_type}
            forSource={false}
          >
            {checkBuildType ? (
              <ContentDetailsSection
                name="re_usable"
                type="text"
                fieldType="radio"
                radioGroupDirection={false}
                options={YES_NO_OPTIONS}
                isEdit
                value={values?.re_usable}
                handleChange={(val) => {
                  setFieldValue('re_usable', val);
                }}
              />
            ) : <Box className={classes.emptyBox} />}
          </BuildTypeWrapper>
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Elucidat Project Type" helper={values?.elucidat_project_type && (`(${values?.elucidat_project_type})`)} />}
        >
          <ContentDetailsSection
            name="elucidat_project_type"
            type="text"
            fieldType="radio"
            radioGroupDirection={false}
            options={ELUCIDAT_PROJECT_TYP_OPTIONS}
            isEdit
            value={values?.elucidat_project_type}
            handleChange={(val) => {
              setFieldValue('elucidat_project_type', val);
            }}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode direction="column" primary="Last Date of Elucidat Standardization" helper={values?.elucidat_std_date_from && values?.elucidat_std_date_to && (`(${moment(values?.elucidat_std_date_from)?.format('DD MMM')}-${moment(values?.elucidat_std_date_to)?.format('DD MMM')})`)} />}
        >
          <Box className={classes.rowDirection}>
            <ContentDetailsSection
              name="elucidat_std_date_from"
              type="text"
              fieldType="calendar"
              placeholder="From"
              isEdit
              value={values?.elucidat_std_date_from}
              handleChange={setFieldValue}
            />
            <ContentDetailsSection
              name="elucidat_std_date_to"
              type="text"
              fieldType="calendar"
              placeholder="To"
              isEdit
              value={values?.elucidat_std_date_to}
              handleChange={setFieldValue}
            />
          </Box>
          <ErrorText
            touchedField
            errorField={errors.elucidat_std_date_to}
          />
        </FilterSection>

        {/* <FilterSection summary="Shared Elucidat Projects" expanded={false}>
          <ContentDetailsSection
            name="shared_elucidat_projects"
            type="text"
            fieldType="radio"
            radioGroupDirection={false}
            options={YES_NO_OPTIONS}
            isEdit
            value={values?.shared_elucidat_projects}
            handleChange={(val) => {
              setFieldValue('shared_elucidat_projects', val);
            }}
          />
        </FilterSection> */}

        <FilterSection
          summary={<SummaryNode direction="column" primary="Last Date of Docebo Standardization" helper={values?.docebo_std_date_from && values?.docebo_std_date_to && (`(${moment(values.docebo_std_date_from).format('DD MMM')}-${moment(values.docebo_std_date_to).format('DD MMM')})`)} />}
        >
          <Box className={classes.rowDirection}>
            <ContentDetailsSection
              name="docebo_std_date_from"
              type="text"
              fieldType="calendar"
              placeholder="From"
              isEdit
              value={values?.docebo_std_date_from}
              handleChange={setFieldValue}
            />
            <ContentDetailsSection
              name="docebo_std_date_to"
              type="text"
              fieldType="calendar"
              placeholder="To"
              isEdit
              value={values?.docebo_std_date_to}
              handleChange={setFieldValue}
            />
          </Box>
          <ErrorText
            touchedField
            errorField={errors.docebo_std_date_to}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Client Name" helper={values?.client_ids?.length > 0 && (`(${values?.client_ids?.length})`)} />}
        >
          <AsyncSelectClient
            placeholder="Select a Client ID or Client Name"
            selectedClients={_map(values?.client_ids,
              (item) => (_has(item, 'label') ? { ...item } : {
                ...item,
                value: item?.client_id,
                label: getClientLabel(item),
              }))}
            onChange={handlePickedClientsClone}
            disableOnLimitReach={false}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Client Set Up" helper={values?.client_setup?.length > 0 && (`(${values?.client_setup?.length})`)} />}
        >
          <ContentDetailsSection
            name="client_setup"
            type="text"
            fieldType="checkbox"
            options={CLIENT_SET_UP_OPTIONS}
            radioGroupDirection={false}
            value={values?.client_setup}
            isEdit
            handleChange={(e) => {
              if (e.target.checked) setFieldValue('client_setup', [...selectedClientSetup, e.target.value]);
              else {
                const list = values?.client_setup.filter((o) => o !== e.target.value);
                setFieldValue('client_setup', list);
              }
            }}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Available Languages for Learning Program" helper={values?.language_codes?.length > 0 && (`(${values.language_codes?.length})`)} />}
        >
          <ContentDetailsSection
            name="language_codes"
            type="text"
            fieldType="checkbox"
            options={LP_LANGUAGES_OPTIONS}
            radioGroupDirection={false}
            value={values?.language_codes}
            isEdit
            handleChange={(e) => {
              if (e.target.checked) setFieldValue('language_codes', [...selectedLanguages, e.target.value]);
              else {
                const list = values?.language_codes.filter((o) => o !== e.target.value);
                setFieldValue('language_codes', list);
              }
            }}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode primary="Status" helper={values?.status?.length > 0 && (`(${values.status?.length})`)} />}
        >
          <ContentDetailsSection
            name="status"
            type="text"
            fieldType="checkbox"
            options={stageOptions}
            radioGroupDirection={false}
            value={values?.status}
            isEdit
            handleChange={(e) => {
              if (e.target.checked) setFieldValue('status', [...selectedStatus, e.target.value]);
              else {
                const list = values?.status.filter((o) => o !== e.target.value);
                setFieldValue('status', list);
              }
            }}
          />
        </FilterSection>

        <FilterSection
          summary={<SummaryNode direction="column" primary="Closeout Date" helper={values?.closeout_date_from && values?.closeout_date_to && (`(${moment(values.closeout_date_from).format('DD MMM')}-${moment(values.closeout_date_to).format('DD MMM')})`)} />}
        >
          <Box className={classes.rowDirection}>
            <ContentDetailsSection
              name="closeout_date_from"
              type="text"
              fieldType="calendar"
              placeholder="From"
              isEdit
              value={values?.closeout_date_from}
              handleChange={setFieldValue}
            />
            <ContentDetailsSection
              name="closeout_date_to"
              type="text"
              fieldType="calendar"
              placeholder="To"
              isEdit
              value={values?.closeout_date_to}
              handleChange={setFieldValue}
            />
          </Box>
          <ErrorText
            touchedField
            errorField={errors.closeout_date_to}
          />
        </FilterSection>
      </Container>

      <Container className={`${classes.globalLogWrapper} ${classes.bottomContainer}`}>
        <Box className={classes.centerAction}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Clear Filter
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {values?.enableEdit ? 'Update & Apply' : primaryCTA}
          </Button>
        </Box>
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        sx={{
          '&.MuiPopover-paper': {
            width: '26%',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {
          popoverData?.type?.length > 0 && (
            <FilterInfoToast
              close={closePopover}
              closeIconStyle={`${classes.closeButton} ${closeIconStyle.closeButton}`}
              data={popoverData}
              filterName={popoverData?.type === 'duplicate_settings' ? popoverData?.data?.name : modalFilterName}
              onDelete={popoverData?.type === 'duplicate_settings' ? moveToDuplcateFilter : deleteFilterGroup}
            />
          )
        }
      </Popover>
    </Box>
  );
};

SummaryNode.defaultProps = {
  direction: 'row',
};

SummaryNode.propTypes = {
  primary: PropTypes.string.isRequired,
  helper: PropTypes.string.isRequired,
  direction: PropTypes.string,
};

LpFiltersDrawer.propTypes = {
  closeFilterDrawer: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  stageOptions: PropTypes.array.isRequired,
  getFilterGroups: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  popoverData: PropTypes.string.isRequired,
  setPayloadIntoForm: PropTypes.func.isRequired,
  selectedFilterGroup: PropTypes.string.isRequired,
  setSelectedFilterGroup: PropTypes.func.isRequired,
  setPopoverData: PropTypes.func.isRequired,
  deleteFilterGroup: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
};
export default LpFiltersDrawer;
