import React, {
  useState, useEffect, useCallback,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import Loader from '../../components/Loader';
import { internalServerErrorModalLogic } from '../common/utils';
import FileInput from '../../components/FileInput';
import MainButton from '../../components/MainButton';
import ValidationError from '../../assets/img/validation-error.webp';
import alertIcon from '../../assets/icons/alert-icon.svg';
import BulkUpdateAccountsHeader from '../../components/BulkUpdateAccounts/BulkUpdateAccountsHeader';
import BulkUpdateConfirmation from '../../components/BulkUpdateAccounts/BulkUpdateConfirmation';
import FormattedTypography from '../../components/common/FormattedTypography';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import XlsScanError from '../../components/common/XlsScanError';

import { parseValidationResult } from '../../helpers/xlsxValidation';
import { zeroAppender } from '../../helpers/formattingHelpers';
import {
  MCKINSEY_BLUE, MODERATE_DARK_GREY,
} from '../../stylesheets/colors';
import {
  BULK_UPDATE_ACCOUNTS_TEMPLATE, FEATURE_CLICKED,
} from '../../constants';
import {
  getProgress,
} from '../common/apis';
import { validateBulkUpdateXLSXApiCall, uploadXlsx } from './apis';
import WarningInfoAlert from '../../components/common/WarningInfoAlert';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 4rem',
    marginTop: '2.5rem',
  },
  uploadSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
  },
  border: {
    marginTop: '2rem',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '50%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  timelineWrapper: {
    display: 'flex',
    flex: 0.2,
    flexDirection: 'column',
    paddingLeft: '6rem',
  },
  alertBox: {
    '& .MuiAlert-root': {
      padding: '4px 16px',
    },
  },
  DownloadFileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const BulkUpdateAccountsPage = ({
  match, onProgramMetadataRefresh, transactionIds,
}) => {
  const classes = useStyles();

  const programSubType = get(match, 'params.programSubType');
  const programId = get(match, 'params.programId');

  const initialValidationResult = { status: 'none', errors: [] };
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [progress, setProgress] = useState({ done: true, percentage: 0 });
  const [pollProgress, setPollProgress] = useState(true);
  const [modalState, setModalState] = useState(null);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
    CONFIRMATION: {
      component: BulkUpdateConfirmation,
      wrapperProps: {
        logo: <img src={alertIcon} alt="alert icon" width={120} height={120} />,
        heading: null,
        primaryBtnText: '',
        primaryBtnProps: null,
      },
      props: {},
    },
  });

  const history = useHistory();

  const onDownloadSample = () => {
    window.open(BULK_UPDATE_ACCOUNTS_TEMPLATE, '_blank');
  };

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('sub_program_type', programSubType);
      formData.append('program_id', programId);

      return formData;
    },
    [programSubType, programId],
  );

  useEffect(() => {
    if (transactionIds.length) {
      setTransactionId(transactionIds[0]);
      setPollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIds.length]);

  useEffect(() => {
    // TODO: Support multiple transactions
    let timer = null;
    const pollProgressApi = async () => {
      if (!pollProgress) return;
      try {
        if (!transactionIds.length || !transactionId) return;
        const res = await getProgress(transactionId);
        if (res.data.done) {
          setProgress(res.data);
          setPollProgress(false);
        } else {
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollProgress, transactionId, programSubType, history]);

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setModalState(null);
        // adding heap tracking script to track actual feature click
        window.heap.track(FEATURE_CLICKED, programSubType);

        await uploadXlsx(programSubType, formData, programId);
        onProgramMetadataRefresh(programId);
      } catch (e) {
        console.error(e);
        setProgress({ done: true, percentage: 100 });
      }
    },
    [programId, programSubType, onProgramMetadataRefresh],
  );

  const confirmUploadFile = useCallback((formData, count = 0) => {
    batchUpdates(() => {
      setModalState('CONFIRMATION');
      setModalScheme((schema) => ({
        ...schema,
        CONFIRMATION: {
          ...schema.CONFIRMATION,
          wrapperProps: {
            ...schema.CONFIRMATION.wrapperProps,
            heading: null,
            primaryBtnText: 'Continue Upload',
            primaryBtnProps: {
              onClick: () => uploadFile(formData),
            },
            secondaryBtnText: 'Cancel',
            secondaryBtnProps: {
              onClick: () => setModalState(null),
            },
          },
          props: {
            ...schema.CONFIRMATION.props,
            count,
          },
        },
      }));
    });
  }, [uploadFile]);

  const onUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      const formData = generateFormData(file);
      resetFileUpload(event);
      let result;
      try {
        result = await validateBulkUpdateXLSXApiCall(
          programSubType,
          formData,
        );
      } catch (e) {
        console.error(e);
        return;
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;
      const [extension, ...nameParts] = file.name.split('.').reverse();

      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
        }));
      });

      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        return;
      }

      confirmUploadFile(formData, result?.count);
    },
    [generateFormData,
      programSubType,
      confirmUploadFile,
      resetFileUpload],
  );

  const shouldShowLoader = !progress.done;

  const loader = (
    <Loader
      progress={progress.percentage}
      hideCancel
    />
  );

  const body = (
    <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
      <Box className={classes.uploadSection}>
        <Box className={classes.DownloadFileWrapper}>
          <Typography variant="body1" style={{ width: '100', color: MODERATE_DARK_GREY, fontWeight: 900 }}>Download the sample file</Typography>
          <Button
            variant="contained"
            style={{ marginLeft: '2rem', width: '20%' }}
            color="primary"
            onClick={onDownloadSample}
          >
            Download XLS file
          </Button>
        </Box>
        <Divider light flexItem sx={{ mt: '2rem', width: '50%' }} />
        <Box className={classes.border}>
          <FileInput idx={0} onFileInput={onUpload}>
            <MainButton
              title="Upload XLS file"
              subtitle="We will upload users to activate or deactivate in Docebo"
            />
          </FileInput>
        </Box>
      </Box>
    </Container>
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Paper className={classes.wrapper}>
      <WarningInfoAlert severity="warning" customStyles={classes.alertBox}>
        Note: This action will deactivate users from the DOCEBO Platform irrespective of whether
        they are enrolled in this Program or not.
        After deactivation, the users will not able to login to DOCEBO
      </WarningInfoAlert>
      <BulkUpdateAccountsHeader shouldShow={false} />
      {shouldShowLoader ? loader : body}
      {ModalComponent && (
        <CustomUploadModal
          open={modalSchema[modalState] !== undefined}
          onClose={() => setModalState(null)}
          className={classes.modalHeight}
          breakpoint="md"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modalSchema[modalState].wrapperProps}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      <ErrorModal
        open={isErrorModalOpen}
        onClose={onErrorModalClose}
        message="Could not update users due to some technical error."
      />
    </Paper>
  );
};

BulkUpdateAccountsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
    extendedEnterprise: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
};

export default BulkUpdateAccountsPage;
