import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import LabledTextField from '../common/LabledTextField';
import {
  ERROR_RED, MODERATE_DARK_GREY, SUCCESS_GREEN, LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';
import ErrorRoundIcon from '../../assets/icons/cross-outline2.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';

const useStyles = makeStyles({
  greyText: {
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  errorBlock: {
    marginBottom: '1.2rem',
  },
  errorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.7rem',

    '& img': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
    },
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 1,
    margin: '0 2.5rem 0 0.75rem',
    '& > svg': {
      width: '2rem',
      height: '2rem',
    },
  },
  checkboxLabel: {
    marginTop: '3.4rem',
  },
  iconBtn: {
    padding: '1px',
  },
});

const EditUserPasswordModal = ({
  open, setPassword, setRetypePassword, setPasswordError, forcePassword, setForcePassword,
  sequentialError, setSequentialError,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = (name) => {
    if (name === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  // eslint-disable-next-line
  const specialCharsValidation = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const hasNumberValidation = /\d/;
  const sequentialValidation = /(.)\1\1/i;

  const validate = (values) => {
    const errors = {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
      repeating_char: true,
    };

    if (values.password && values.confirmPassword === values.password) {
      delete errors.confirmPasswordError;
    }

    if (values.password.length >= 12) {
      delete errors.min_characters;
    }
    if (specialCharsValidation.test(values.password)) {
      delete errors.no_special_char;
    }
    if (hasNumberValidation.test(values.password)) {
      delete errors.no_number;
    }
    if (!sequentialValidation.test(values.password)) {
      delete errors.repeating_char;
    }

    if (Object.keys(errors).length) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    return errors;
  };

  const {
    values, handleChange, errors, resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validate,
    initialValues: { password: '', confirmPassword: '' },
    initialErrors: {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
      repeating_char: true,
    },
  });

  useEffect(() => {
    if (open) {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    confirmPasswordError, min_characters, no_special_char, no_number, repeating_char,
  } = errors;

  const getErrorBlock = useCallback((showErrorIcon, errorMsg) => (
    <Box className={classes.errorRow}>
      {showErrorIcon ? <img src={ErrorRoundIcon} alt="error-cross" /> : <img src={CheckRoundIcon} alt="success-tick" /> }
      <Typography style={{ color: showErrorIcon ? ERROR_RED : SUCCESS_GREEN }} variant="body1">{errorMsg}</Typography>
    </Box>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const handlePasswordChange = useCallback((e) => {
    if (e.target.value.indexOf(' ') >= 0) { // restrict space
      return;
    }
    handleChange(e);
    setSequentialError('');
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
    if (e.target.name === 'confirmPassword') {
      setRetypePassword(e.target.value);
    }
  }, [handleChange, setPassword, setRetypePassword, setSequentialError]);

  const handleCheckBox = (e) => {
    setForcePassword(e.target.checked);
    handleChange(e);
  };

  return (
    <>
      <Grid item xs={4}>
        <LabledTextField
          label="Password"
          placeholder={'Type new password'}
          name="password"
          type={showPassword ? 'text' : 'password'}
          labelClass={classes.greyText}
          value={values.password}
          onChange={(e) => { handlePasswordChange(e); }}
          inputProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { toggleShowPassword('password'); }}
                    onMouseDown={() => { toggleShowPassword('password'); }}
                    className={classes.iconBtn}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        {
          values.password && (
          <Box className={classes.errorBlock}>
            {
            sequentialError && getErrorBlock(true, sequentialError)
            }
            {!sequentialError && getErrorBlock(min_characters, 'Password must be minimum 12 characters')}
            {!sequentialError && getErrorBlock(no_special_char, 'Password must have at least one special character')}
            {!sequentialError && getErrorBlock(no_number, 'Password must have at least one numeric value')}
            {!sequentialError && getErrorBlock(repeating_char, 'Password cannot contain 3 or more repeating characters')}
          </Box>
          )
        }

      </Grid>
      <Grid item xs={4}>
        <LabledTextField
          label="Re-Type Password"
          placeholder={'Retype password'}
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          labelClass={classes.greyText}
          value={values.confirmPassword}
          onChange={(e) => { handlePasswordChange(e); }}
          inputProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { toggleShowPassword('confirmPassword'); }}
                    onMouseDown={() => { toggleShowPassword('confirmPassword'); }}
                    className={classes.iconBtn}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        {
          values.confirmPassword && (
          <Box className={classes.errorBlock}>
            <Box className={classes.errorRow}>
              {!sequentialError && getErrorBlock(confirmPasswordError, 'Confirm password must match new password')}
            </Box>
          </Box>
          )
        }

      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          key="force_password"
          value="Force password change"
          className={classes.checkboxLabel}
          control={(
            <Checkbox
              className={classes.checkbox}
              checked={forcePassword}
              name="force_password_change"
              classes={{
                checked: classes.checkboxChecked,
              }}
              onChange={(e) => { handleCheckBox(e); }}
            />
                  )}
          label="Force password change"
        />
      </Grid>

    </>
  );
};

EditUserPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  setPassword: PropTypes.func.isRequired,
  setRetypePassword: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  forcePassword: PropTypes.bool.isRequired,
  setForcePassword: PropTypes.func.isRequired,
  sequentialError: PropTypes.string.isRequired,
  setSequentialError: PropTypes.func.isRequired,
};

export default EditUserPasswordModal;
