import {
  SET_LOADING,
  CLEAR_ALERTBAR,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILED,
  SET_SNACKBAR,
  SET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_STORE,
  SET_PLATFORM_DETAILS_LOADING, TOGGLE_USER_DETAIL_ALERTBAR,
} from '../../constants';
import { snackBarInitialState } from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';

const initialState = {
  isLoading: true,
  isPlatformDetailsLoading: false,
  result: { },
  snackbarObj: snackBarInitialState,
  languageOptions: [],
  primaryExperienceOptions: [],
  editDoceboDetails: false,
  alertBarValues: { labelText: '', variant: '', open: false },
};

function onFetchSuccess(state, { results }) {
  return {
    ...state,
    result: results?.data || { noUserFound: 'no user found' },
    isLoading: false,
  };
}

function onSetUserDetailsSuccess(state, { results }) {
  return {
    ...state,
    result: { ...state.result, ...results }, // Updating name and email
    isLoading: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state,
    isLoading: false,
    isPlatformDetailsLoading: false,
    snackbarObj: { open: true, severity: 'error', message: getErrorMessage(null, message) },
  };
}

function setLoading(state, { payload }) {
  return {
    ...state, isLoading: payload,
  };
}

function setPlatformDetailsLoading(state, { payload }) {
  return {
    ...state, isPlatformDetailsLoading: payload,
  };
}

function clearSnackbar(state) {
  return {
    ...state, snackbarObj: snackBarInitialState,
  };
}

function setSnackbarObj(state, { payload }) {
  const snackbarObj = payload;
  return {
    ...state, snackbarObj,
  };
}

function updateStore(state, { payload }) {
  return {
    ...state, ...payload,
  };
}

function setUserDetailAlertBar(state, { payload }) {
  return {
    ...state, alertBarValues: payload,
  };
}

export default function platformUserDetailsData(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ALERTBAR:
      return clearSnackbar(state, action);
    case SET_SNACKBAR:
      return setSnackbarObj(state, action);
    case SET_LOADING:
      return setLoading(state, action);
    case SET_PLATFORM_DETAILS_LOADING:
      return setPlatformDetailsLoading(state, action);
    case FETCH_USER_DETAILS_SUCCESS:
      return onFetchSuccess(state, action);
    case SET_USER_DETAILS_SUCCESS:
      return onSetUserDetailsSuccess(state, action);
    case FETCH_USER_DETAILS_FAILED:
      return onFetchFailure(state, action);
    case UPDATE_USER_DETAILS_STORE:
      return updateStore(state, action);
    case TOGGLE_USER_DETAIL_ALERTBAR:
      return setUserDetailAlertBar(state, action);
    default:
      return state;
  }
}
