import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DOMPurify from 'isomorphic-dompurify';
import { Button, Typography } from '@material-ui/core';
import SettingValueCell from './SettingValueCell';
import { REQUIRED } from '../../constants';
import { MEDIUM_GREY } from '../../stylesheets/colors';

const defaultSettingsValue = {
  name: '',
  description: '',
};

const validationSchema = yup.object({
  name: yup.string().trim().required(REQUIRED).nullable(),
  description: yup.string().required(REQUIRED).nullable(),
});

const SettingsView = ({
  settings,
  isEdit,
  onSubmit,
  onEdit,
  onCancel,
}) => {
  const defaultData = settings?.program_id ? settings : defaultSettingsValue;

  const [editorInstance, setEditorInstance] = useState(null);

  const {
    values, handleSubmit, errors, touched, handleChange, setFieldValue, resetForm,
  } = useFormik({
    initialValues: { ...defaultData },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (payload) => {
      onSubmit(payload);
    },
  });

  const cancelEdit = () => {
    if (editorInstance) {
      const config = {
        FORBID_TAGS: ['script', 'iframe', 'style'], // Add tags you want to block here
        FORBID_ATTR: ['href', 'onclick'], // Add attributes you want to block here
      };
      editorInstance.setData(DOMPurify.sanitize(editorInstance.getData(), config));
    }
    resetForm();
    onCancel();
  };

  const saveChanges = () => {
    if (editorInstance) {
      const config = {
        FORBID_TAGS: ['script', 'iframe', 'style'], // Add tags you want to block here
        FORBID_ATTR: ['href', 'onclick'], // Add attributes you want to block here
      };
      editorInstance.setData(DOMPurify.sanitize(editorInstance.getData(), config));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-header">
        <div>
          <span className="form-header-title">Content</span>
        </div>
        <div>
          {!isEdit && (
            <Button color="primary" variant="contained" onClick={() => onEdit()}>
              Edit
            </Button>
          )}
          {isEdit && (
            <>
              <Button color="primary" variant="outlined" onClick={() => cancelEdit()}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={() => saveChanges()} type="submit">
                Save Changes
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="form-content">
        <div className="form-group flex">
          <div className="form-row form-column">
            <SettingValueCell
              label="Learning Plan Name"
              name="name"
              value={values.name}
              isEdit={isEdit}
              fieldType="text"
              hasError={touched.name && !!errors.name}
              errorText={touched.name && errors.name}
              handleChange={handleChange}
            />
            <SettingValueCell
              name="description"
              label="Learning Plan Description"
              helperText={isEdit && '(Ensure to exit HTML or Source mode by clicking on Source within the editor and then click on Save button on the page)'}
              value={values.description}
              isEdit={isEdit}
              fieldType="editor"
              hasHTML
              hasError={touched.description && !!errors.description}
              errorText={touched.description && errors.description}
              handleChange={setFieldValue}
              programId={values.program_id}
              editorInstance={editorInstance}
              setEditorInstance={setEditorInstance}
            />
            {isEdit && (
              <Typography variant="body1" sx={{ color: MEDIUM_GREY }} />
            )}
          </div>
          <div className="form-row" />

        </div>
      </div>
    </form>
  );
};

SettingsView.defaultProps = {
  isEdit: false,
};

SettingsView.propTypes = {
  settings: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default SettingsView;
