import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { Provider } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import MyContext from './context';
import theme from './stylesheets/theme';
import { headerTabs, Routes } from './featureConfig';
import { getNvtUserInfo } from './helpers/nvtUserInfoHelper';
import { setInLocalStorage, getEnvironmentFromLocalStorage } from './helpers/userHelper';
import AllowedRoutes from './AllowedRoutes';
import EnvironmentBanner from './components/EnvironmentBanner';
import { getEnvironment, getLtiToken } from './containers/BasePage/apis';
import Header from './components/Header';
import LoadingCircle from './components/common/LoadingCircle/LoadingCircle';
import {
  COURSE_PROD,
  COURSE_OPS,
  ROLE_ADMIN,
  ROLE_OPS,
  ROLE_PROD,
  USER_INFO_KEY,
  ENVIRONMENT_KEY,
  LTI_TOKEN,
  ROLE_SUPERADMIN,
} from './constants';
import ActivityLogsContainer from './ActivityLogsContainer';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
  },
  containerWrapper: {
    display: 'flex',
    flex: 1,
  },
  contentWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  mainWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
});

const queryClient = new QueryClient({});

const Routing = ({ store, history }) => {
  const classes = useStyles();
  const st = store.getState();
  const { mid } = st.mid;

  const environment = getEnvironmentFromLocalStorage();
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [programType, setProgramType] = useState('');
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

  const setLtiToken = async () => {
    const ltiToken = await getLtiToken();
    setInLocalStorage(LTI_TOKEN, ltiToken);
  };

  useEffect(() => {
    const getUserAsync = async () => {
      setIsLoading(true);
      try {
        const userData = await getNvtUserInfo();
        setIsLoading(false);
        setInLocalStorage(USER_INFO_KEY, userData);
        const teams = get(userData, 'teams', []);
        setRole(() => {
          if (teams.includes(ROLE_SUPERADMIN)) {
            return ROLE_SUPERADMIN;
          }
          if (teams.includes(ROLE_ADMIN)) {
            return ROLE_ADMIN;
          }
          if (teams.includes(ROLE_PROD)) {
            return ROLE_PROD;
          }
          if (teams.includes(ROLE_OPS)) {
            return ROLE_OPS;
          }
          return ROLE_ADMIN;
        });
        if (teams.includes(ROLE_OPS)) {
          setProgramType(COURSE_OPS);
        } else {
          setProgramType(COURSE_PROD);
          setLtiToken();
        }
      } catch (err) {
        setIsLoading(false);
        setProgramType(COURSE_PROD);
        setRole(ROLE_ADMIN);
      }
    };
    getUserAsync();
  }, []);
  useEffect(() => {
    const getUserAndEnv = async () => {
      const env = await getEnvironment();
      setInLocalStorage(ENVIRONMENT_KEY, env);
    };

    getUserAndEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextProviderValue = useMemo(() => ({
    role, programType, isActivityLogModalOpen, setIsActivityLogModalOpen,
  }), [role, programType, isActivityLogModalOpen]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConnectedRouter history={history}>
              <MyContext.Provider value={contextProviderValue}>
                <div className={classes.wrapper}>
                  <div className={classes.containerWrapper}>
                    {
                        isLoading ? <LoadingCircle />
                          : (
                            <>
                              <div className={classes.contentWrapper}>

                                <EnvironmentBanner environment={environment.environment} />
                                <Header allowedRoutes={headerTabs} />
                                <Box mid={mid} className={classes.mainWrapper}>
                                  <AllowedRoutes allowedRoutes={Routes} role={role} />
                                </Box>
                              </div>
                              <ActivityLogsContainer allowedActivityLog={headerTabs} role={role} />
                            </>
                          )
                      }
                  </div>
                </div>
              </MyContext.Provider>
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

Routing.propTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Routing;
