import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper, Box, Container } from '@mui/material';
import { Typography, Divider, Button } from '@material-ui/core';
import { isCancel } from 'axios';
import Header from '../common/Header';
import WarningInfoAlert from '../common/WarningInfoAlert';
import CustomSelect from '../common/CustomSelect';
import BreadCrumbHOC from '../BreadCrumbHOC';
import FileInput from '../FileInput';
import MainButton from '../MainButton';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  BULK_ACTION_USER_TEMPLATE, QUEUED, USER_DELETION,
  AUTO_HIDE_DURATION, ROUTE_ERROR,
} from '../../constants';
import FormattedTypography from '../common/FormattedTypography';
import {
  MODERATE_DARK_GREY,
  MCKINSEY_BLUE, ERROR_RED,
} from '../../stylesheets/colors';
import XlsScanError from '../common/XlsScanError';
import ValidationError from '../../assets/img/validation-error.webp';
import { zeroAppender } from '../../helpers/formattingHelpers';
import CustomUploadModal from '../common/CustomUploadModal';
import { internalServerErrorModalLogic } from '../../containers/common/utils';
import ProgressStatusModal from './ProgressStatusModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import {
  getProgress,
} from '../../store/actions/async/common';
import AlertReleaseInfo from '../ContentManagement/AlertReleaseInfo';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxShadow: 'none',
  },
  breadCrumbSection: {
    display: 'flex',
    padding: '1.25rem 2rem',
  },
  headingSection: {
    padding: '0rem 2rem 1rem',
  },
  red: {
    color: ERROR_RED,
  },
  mainWrapper: {
    flexGrow: 1,
    padding: '2rem',
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  boldText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  userEnrollMentButton: {
    fontSize: '1.5rem',
  },
  border: {
    margin: '1.2rem 0',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  downloadWaveDataLbl: {
    marginTop: '1rem',
  },
  transferInfo: {
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  pageHeader: {
    padding: '0 2rem 2rem',
  },
  selectWrapper: {
    width: '25%',
  },
  label: {
    fontSize: '1.125rem',
    color: MODERATE_DARK_GREY,
    textTransform: 'unset',
    padding: '0.25rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  divider: {
    marginTop: '1rem',
  },
  contentWrapper: {
    marginBottom: '0',
    marginTop: '5rem',
  },
}));

const userActionOptions = [
  { label: 'Mark for Deletion', value: 'Mark' },
  { label: 'Unmark for Deletion', value: 'Unmark' },
];

const WARNING_text = {
  Mark: 'This action will mark the users for deletion on the  platform. Once the user has been deleted by the Super Admin, the action is not reversible.',
  Unmark: 'This action will unmark the users for deletion on the platform. Once the user has been deleted by the Super Admin, the action can not be performed on those users.',
};

const BulkUsersUpload = (props) => {
  const {
    fileValidate, isLoading, fileValidateError, message, fileUpload,
    transaction_id, clearMessage, clearTransactionId, isCpRole,
  } = props;
  console.log(isCpRole);
  const classes = useStyles();
  const history = useHistory();
  const initialValidationResult = { status: 'none', errors: [] };
  const [selectAction, setSelectAction] = useState('');
  const [actionText, setActionText] = useState('');
  const [fileData, setFileData] = useState({});
  const [modalState, setModalState] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [transactionId, setTransactionId] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [loaderText, setLoaderText] = useState('File upload is in progress...');
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
  });

  useEffect(() => {
    if (isCpRole) {
      history.replace(`/${ROUTE_ERROR}`);
    }
  }, [history, isCpRole]);

  useEffect(() => {
    if (fileValidateError.length && !modalState) {
      const file = fileName?.name || '';
      const [extension, ...nameParts] = file.split('.').reverse();
      batchUpdates(() => {
        setModalState('VALIDATION');
        setUploadDisabled(false);
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.length ? nameParts.join('.') : ''}
                  suffix={`${extension ? `.${extension}` : ''}`}
                  subHeading={`${zeroAppender(
                    fileValidateError.length,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: { errors: fileValidateError },
          },
        }));
        clearMessage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileValidateError]);

  useEffect(() => {
    if (message && message === 'File validation successful') {
      setLoaderText('File Upload is in progress...');
      fileUpload(USER_DELETION, fileData);
      clearMessage();
    }
  }, [message, fileData, fileUpload, clearMessage]);

  useEffect(() => {
    if (transaction_id) {
      setTransactionId(transaction_id);
      setUploadDisabled(true);
    }
  }, [transaction_id]);

  useEffect(() => {
    let handler;
    if (progress?.done) {
      setSelectAction('');
      handler = setTimeout(() => {
        setShowElement(false);
      }, AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [progress]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(transactionId);
        const { done } = res.data;
        if (done) {
          clearTransactionId();
          batchUpdates(() => {
            setProgress(res.data);
            setTransactionId(null);
            setUploadDisabled(false);
          });
        } else {
          batchUpdates(() => {
            setProgress(res.data);
            setUploadDisabled(true);
            if (res.data.status === QUEUED) {
              setOpenStatusModal(true);
            }
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (transactionId) {
      pollProgressApi();
      setShowElement(true);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const onCloseModal = () => {
    setModalState(null);
  };

  const breadCrumbList = [
    { label: 'User Management', redirectionUrl: '/users', isActive: false },
    { label: 'Bulk Action XLS Upload', redirectionUrl: '', isActive: true },
  ];

  const onDownloadSample = async () => {
    window.open(BULK_ACTION_USER_TEMPLATE, '_blank');
  };

  const resetFileUpload = useCallback(
    (e) => {
      e.target.value = '';
    },
    [],
  );

  const onUpload = (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    resetFileUpload(e);
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('flag_type', selectAction);
    setProgress({ done: null, percentage: 0 });
    setActionText(selectAction);
    setFileName(selectedFile);
    setFileData(formData);
    setUploadDisabled(true);
    setLoaderText('File Validation is in progress...');
    try {
      fileValidate(USER_DELETION, formData);
    } catch (err) {
      console.log(err);
      setUploadDisabled(false);
    }
  };

  const isButtonDisabled = () => !selectAction || uploadDisabled;

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  const onViewStatus = useCallback(() => {
    batchUpdates(() => {
      setOpenStatusModal(true);
    });
  }, []);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      {
        isLoading && (
          <CustomUploadModal open contentWrapper={classes.contentWrapper} breakpoint="sm">
            <LoadingCircle labelText={loaderText} />
          </CustomUploadModal>
        )
      }
      {
        (showElement || isLoading) && (
          <AlertReleaseInfo
            progress={progress}
            showElement={showElement || isLoading}
            progressMessage={isLoading
              ? `Validating ${actionText} for Deletion file '${fileName?.name ?? ''}'`
              : `Uploading ${actionText} for Deletion file '${progress?.file_name || fileName?.name}'`}
            withErrorsMessage={`${actionText} for Deletion file '${progress?.file_name}' upload was completed with errors.`}
            failedMessage={`Failed to upload ${actionText} for Deletion file '${progress?.file_name}'. Please try again.`}
            successMessage={`${actionText} for Deletion file '${progress?.file_name}' was successfully uploaded`}
            onViewStatus={onViewStatus}
            setShowElement={setShowElement}
          />
        )
      }
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
        />
      </Box>
      <Header
        heading="Bulk Action for User Management"
        subHeading="Bulk Upload for actions to be performed on users on the platform"
        className={classes.pageHeader}
      />

      <Paper className={classes.wrapper} elevation={0}>
        {
          selectAction && (
            <WarningInfoAlert severity={'warning'}>
              {`Note: ${WARNING_text[selectAction]}`}
            </WarningInfoAlert>
          )
        }

        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.selectWrapper}>
            <CustomSelect
              name="select_action"
              options={userActionOptions}
              className={`${classes.fieldPadding} ${classes.experience_type_label}`}
              defaultValue={
                selectAction
                  ? userActionOptions.find((item) => item.value === selectAction)
                  : null
              }
              placeholder="Select an Action"
              type="select"
              label={requiredFieldLabel('Choose a bulk action to be performed on the users', classes.experience_type_label)}
              onChange={(v) => setSelectAction(v.value)}
              disabled={uploadDisabled}
            />
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />

          <Box className={classes.border}>
            <FileInput
              idx={0}
              onFileInput={onUpload}
              isDisabled={isButtonDisabled()}
            >
              <MainButton
                title="Upload XLS file"
                subtitle={selectAction ? `We will ${selectAction?.toLowerCase()} users uploaded for deletion` : ''}
                isDisabled={isButtonDisabled()}
              />
            </FileInput>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.downloadTempBtn}>
            <Typography variant="body1" className={classes.downloadWaveDataLbl}>Need help configuring the file? Download the template below.</Typography>
            <Button
              variant="outlined"
              style={{ marginTop: '0.75rem', width: 'fit-content' }}
              color="primary"
              onClick={onDownloadSample}
            >
              Download Sample Template
            </Button>
          </Box>
        </Container>
      </Paper>
      {modalState && (
        <CustomUploadModal open onClose={onCloseModal} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      {openStatusModal ? (
        <ProgressStatusModal
          open={openStatusModal}
          onClose={() => { setOpenStatusModal(false); }}
          progress={progress}
          action={actionText}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </Paper>
  );
};

BulkUsersUpload.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  isCpRole: PropTypes.bool.isRequired,
  fileValidateError: PropTypes.array.isRequired,
  fileValidate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  fileUpload: PropTypes.func.isRequired,
  transaction_id: PropTypes.number.isRequired,
  clearMessage: PropTypes.func.isRequired,
  clearTransactionId: PropTypes.func.isRequired,
};

export default BulkUsersUpload;
