import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bodyWrapper: {
    flex: 1,
    padding: '0 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
    color: theme.palette.grey[500],
  },
}));

const CustomModal = ({
  className, children, open, onClose, breakpoint, modalStyle, isLoading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={breakpoint}
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      classes={{ paper: `${classes.wrapper} ${modalStyle}` }}
    >
      <Container data-testid="modal" className={`${classes.bodyWrapper} ${className}`}>
        {onClose && !isLoading && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </Container>
    </Dialog>
  );
};

CustomModal.defaultProps = {
  className: '',
  children: <></>,
  breakpoint: 'md',
  onClose: undefined,
  modalStyle: '',
  isLoading: false,
};

CustomModal.propTypes = {
  className: PropTypes.string,
  breakpoint: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  modalStyle: PropTypes.string,
  isLoading: PropTypes.string,
};

export default CustomModal;
