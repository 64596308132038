import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import LabledTextField from '../../common/LabledTextField';
import CtaActions from '../../common/CtaActions';
import DropDownMenu from '../../CreateTestUsers/DropdownMenu';
import {
  MCKINSEY_BLUE, MODERATE_DARK_GREY, ACCORDION_GREY,
} from '../../../stylesheets/colors';
import MCKINSEY_SANS from '../../../stylesheets/fonts';

const useStyles = makeStyles(() => ({
  componentsBreakdown: {
    fontSize: '1.125rem',
    fontFamily: MCKINSEY_SANS,
    color: MODERATE_DARK_GREY,
    paddingRight: '2rem',
  },
  refreshButton: {
    color: MCKINSEY_BLUE,
    fontSize: '1rem',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontWeight: 600,
  },
  tableTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '2rem',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',

    '& div': {
      flex: 'unset',
      borderRadius: 'unset',
    },
    '&  > button:nth-child(2)': {
      width: '10.75rem',
      margin: '0 0 0 1.25rem',
      height: '2.6rem',
      '&  > span': {
        fontFamily: MCKINSEY_SANS,
        color: MCKINSEY_BLUE,
        fontSize: '1rem',
      },
    },
    '&  > button:nth-child(2):disabled': {
      '&  > span': {
        fontFamily: MCKINSEY_SANS,
        color: ACCORDION_GREY,
        fontSize: '1rem',
      },
    },
  },
  inputBox: {
    width: '21rem',
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
  input: {
    paddingRight: '0 !important',
    paddingTop: '0.715rem !important',
    paddingBottom: '0.715rem !important',
  },
  clearFilters: {
    margin: '1rem 0 0 1.25rem',
    fontSize: '1rem',
    fontFamily: MCKINSEY_SANS,
    fontWeight: 600,
    color: MCKINSEY_BLUE,
    background: 'none',
    textDecoration: 'underline',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionCTAWrapper: {
    marginLeft: '2rem',
    '& >button': {
      height: '3rem',
    },
  },
  labelWrapper: {
    padding: '1rem 0 0 0',
    marginBottom: '-1rem',
  },
}));

const ListHeader = ({
  searchText, noOfSelectedFilters, componentCount, getContentMetadata, onRefresh, metadata,
  disableTableOperations, handleApplySearchFilters, setShowFilterModal, handleClearFilters,
  isDDAdmin, enableReordering, presetReordering, setConfimationModal, cancelReordering, dropdownRef,
  menuItems, selectedComponents,
}) => {
  const classes = useStyles();

  const searchFieldIcon = searchText ? (
    <ClearIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => { handleApplySearchFilters({ searchInput: '' }); }} />
  ) : (<SearchIcon style={{ cursor: 'pointer' }} fontSize="small" />);

  const getFilterCount = () => (noOfSelectedFilters > 0 ? `(${noOfSelectedFilters})` : '');

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" spacing={1} alignItems="center">
            {!searchText && !noOfSelectedFilters && componentCount}
            <Grid item>
              {getContentMetadata()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.componentsBreakdown}>
          Last updated data from Docebo:
          {' '}
          {metadata.lastRefreshed}
          <button onClick={() => { onRefresh(); }} className={classes.refreshButton} type="button">Refresh</button>
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.labelWrapper}>
        Search
      </Typography>

      <Box className={classes.tableTopSection}>
        <div className={classes.searchBox}>
          <LabledTextField
            // label="Search"
            placeholder="Search by Title/Code/Docebo ID…"
            labelClass={classes.componentsBreakdown}
            customCss={`${classes.inputBox}`}
            disabled={disableTableOperations}
            inputProps={{
              inputProps: {
                name: 'configId',
                className: classes.input,
              },
              InputProps: {
                endAdornment: searchFieldIcon,
              },
            }}
            value={searchText}
            onChange={(e) => { handleApplySearchFilters({ searchInput: e.target.value }); }}
          />
          <Button variant="outlined" color="primary" onClick={() => { setShowFilterModal(true); }} disabled={disableTableOperations}>
            {`Filters ${getFilterCount()}`}
          </Button>
          {noOfSelectedFilters > 0 && (
            <button className={classes.clearFilters} type="button" onClick={() => { handleClearFilters(); }}>
              Clear Filters
            </button>
          )}
        </div>
        <Box
          className={`${classes.actionWrapper}`}
        >
          {!isDDAdmin && (
            <CtaActions
              ctaText="Re-Order Components"
              isReorderingEnabled={enableReordering}
              onEnableReordering={presetReordering}
              onSave={() => setConfimationModal(true)}
              onCancel={cancelReordering}
            />
          )}
          {!enableReordering && (
            <Box className={classes.actionCTAWrapper}>
              <DropDownMenu title="Update User Access" ref={dropdownRef} menuItems={menuItems} disableBtn={!selectedComponents.length || enableReordering}>
                {menuItems.map((value) => (
                  <MenuItem
                    key={value.key}
                    disabled={value.disable || false}
                    onClick={value.onClick}
                  >
                    {value.title}
                  </MenuItem>
                ))}
              </DropDownMenu>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

ListHeader.defaultProps = {
  componentCount: null,
};

ListHeader.propTypes = {
  searchText: PropTypes.string.isRequired,
  noOfSelectedFilters: PropTypes.number.isRequired,
  componentCount: PropTypes.node,
  getContentMetadata: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  disableTableOperations: PropTypes.bool.isRequired,
  handleApplySearchFilters: PropTypes.func.isRequired,
  setShowFilterModal: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  enableReordering: PropTypes.bool.isRequired,
  presetReordering: PropTypes.func.isRequired,
  setConfimationModal: PropTypes.func.isRequired,
  cancelReordering: PropTypes.func.isRequired,
  dropdownRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  menuItems: PropTypes.array.isRequired,
  selectedComponents: PropTypes.array.isRequired,
};

export default ListHeader;
