import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import TableBody from '@material-ui/core/TableBody';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

import EnhancedTableRow from './EnhancedTableRow';
import { tableSort, getComparator } from '../../helpers/utils';

const useStyles = makeStyles({
  wrapper: {
    '& .MuiTableCell-body': {
      fontSize: '1.25rem',
      height: '1.25rem',
    },
  },
  componentTitle: {
    display: 'flex',
    alignItems: 'center',

    '& :hover': {
      cursor: 'pointer',
    },

    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },

    '& span': {
      marginLeft: '2.25rem',
    },
  },
  contentType: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      height: '1.25rem',
      width: '1.25rem',
    },
    '& span': {
      marginLeft: '4px',
    },
  },
  userAccess: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  trainingMatCount: {
    cursor: 'pointer',
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
  },
  orderIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    height: '1.25rem',
    width: ' 1.25rem',
    borderRadius: ' 50%',
  },
  orderText: {
    paddingLeft: '0.5rem',
  },
});

const EnhancedTableBody = ({
  rows, onTmSelect, goToView, openCopyComponentModal, order, orderBy, copiedComponentData,
  enableReordering, openEOPComponentModal, isDDAdmin, handleCheckboxChange, selectedComponents,
  updatedComponentsId, onDragEnd,
}) => {
  const classes = useStyles();

  const myRef = useRef(null);

  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (updatedComponentsId.length > 0
      || copiedComponentData.new_component_code) {
      executeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef.current]);

  const tableData = order
    ? tableSort(rows, getComparator(order, orderBy)) : rows;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all" type="column">
        {(provided) => (
          <TableBody
            className={classes.wrapper}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {tableData.map((row, index) => (
              <EnhancedTableRow
                key={row.docebo_id}
                row={row}
                index={index}
                enableReordering={enableReordering}
                selectedComponents={selectedComponents}
                handleCheckboxChange={handleCheckboxChange}
                updatedComponentsId={updatedComponentsId}
                copiedComponentData={copiedComponentData}
                goToView={goToView}
                myRef={myRef}
                openEOPComponentModal={openEOPComponentModal}
                onTmSelect={onTmSelect}
                openCopyComponentModal={openCopyComponentModal}
                isDDAdmin={isDDAdmin}
              />
            ))}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

EnhancedTableBody.defaultProps = {
  copiedComponentData: {
    new_component_title: '',
    new_component_code: '',
  },
};

EnhancedTableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  onTmSelect: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  openCopyComponentModal: PropTypes.func.isRequired,
  openEOPComponentModal: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  copiedComponentData: PropTypes.shape({
    new_component_title: PropTypes.string,
    new_component_code: PropTypes.string,
  }),
  isDDAdmin: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectedComponents: PropTypes.array.isRequired,
  updatedComponentsId: PropTypes.array.isRequired,
  enableReordering: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

export default EnhancedTableBody;
