/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import get from 'lodash/get';
import Box from '@mui/material/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import qs from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { isCancel } from 'axios';
import {
  USER_INFO_KEY, ROUTE_REPORT, WAVE_MANAGEMENT, TRANSFER_COHORT_USERS,
  COMPLETED_WITH_ERRORS, FAILED, QUEUED, COHORT_USER_METADATA, BULK_EDIT_USER_PASSWORD,
} from '../../constants';

import { getUserFromLocalStorage } from '../../helpers/userHelper';
import WaveManagement from '../../components/WaveManagement';
import CohortDetails from '../../components/WaveManagement/CohortDetails';
import EnrollmentMetaData from '../../components/WaveManagement/EnrollmentMetaData';
import UserListWaveTransfer from '../../components/WaveManagement/UserListWaveTransfer';
import EditCohort from '../../components/WaveManagement/EditCohort';
import EditMetadata from '../../components/WaveManagement/EditMetaData';
import AddCohortModal from '../../components/WaveManagement/AddCohortModal';
import UpdatePasswordModal from '../../components/WaveManagement/UpdatePasswordModal';
import DeleteWaveModal from '../../components/WaveManagement/DeleteWavemodal';
import XlsScanError from '../../components/common/XlsScanError';
import BlankDueDateWarning from '../../components/WaveManagement/BlankDueDateWarning';
import SetDefaultEnrollmentCohort from '../../components/WaveManagement/SetDefaultEnrollmentCohort';
import TransferStatusModal from '../../components/WaveManagement/TransferStatusModal';
import {
  getWaves, createWave, updateWave, deleteCohort, uploadXlsx, postComponentDueDateApi,
  downloadEnrollMentMetaData, updateCohortUserList, getUntaggedUser, updateDefaultCohort,
  fetchClientId, updateCohortPassword, checkUsersOnClientId,
} from './api';
import { blobToJson } from '../../helpers/apiHelper';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import UserWarning from '../../assets/img/deleteIconJournaling.svg';
import FormattedTypography from '../../components/common/FormattedTypography';
import {
  validateXLSXApiCall, getProgress,
} from '../common/apis';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import ValidationError from '../../assets/img/validation-error.webp';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { internalServerErrorModalLogic } from '../common/utils';
import { zeroAppender } from '../../helpers/formattingHelpers';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  dialogcontentBox: {
    marginBottom: '2rem',
  },
  dialogcontentBoxForValidation: {
    display: 'none',
  },
  subHeadingSection: {
    marginBottom: '2rem',
  },
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultCohortHeadingBody: {
    marginTop: '2rem',
  },
  defaultCohortSubHeadingSection: {
    marginBottom: '2rem',
    '& > div': {
      fontSize: '1.25rem',
    },
  },
  alertBarStyle: {
    marginBottom: '2px',
  },
});

const WaveManagementPage = (props) => {
  const {
    match, location, programMetadata,
  } = props;
  const programId = Number.parseInt(get(match, 'params.programId'), 10);
  const waveId = get(match, 'params.transactionId');
  const enrollmentMetaData = get(match, 'params.feature') === 'userMetaData';
  const programSubType = enrollmentMetaData ? COHORT_USER_METADATA : TRANSFER_COHORT_USERS;
  const programAction = {
    [COHORT_USER_METADATA]: COHORT_USER_METADATA,
    [TRANSFER_COHORT_USERS]: TRANSFER_COHORT_USERS,
    [BULK_EDIT_USER_PASSWORD]: BULK_EDIT_USER_PASSWORD,
  };
  const query = qs.parse(location.search);
  const tranferUsersComp = get(query, 'transferUsers');
  const userInfo = getUserFromLocalStorage(USER_INFO_KEY);
  const classes = useStyles();
  const history = useHistory();
  const configId = programMetadata.config_id;
  const isAcademyGo = programMetadata.is_academy_go;
  const initialValidationResult = { status: 'none', errors: [] };
  const [createWaveModalState, setcreateWaveModalState] = useState(false);
  const [isCohortEdit, setIsCohortEdit] = useState(false);
  const [cohortData, setCohortData] = useState({});
  const [clientIdList, setClientIdList] = useState([]);
  const [waveTitleUniqueError, setWaveTitleUniqueError] = useState(false);
  const [waveTableData, setWaveTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({ type: 'warning', message: '' });
  const [modalState, setModalState] = useState(null);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Unable to connect to Docebo. Please try later.');
  const [isSuccess, setSuccess] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [userCloseModal, setUserCloseModal] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isComponentEdit, setIsComponentEdit] = useState(false);
  const [isEditMetaDataModal, setisEditMetaDataModal] = useState(false);
  const [editMetadataUsers, setEditMetaDataUsers] = useState([]);
  const [refreshUserList, setRefreshUserList] = useState(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [action, setAction] = useState('');

  const [modalSchema, setModalScheme] = useState({
    DELETE: {
      component: DeleteWaveModal,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: <FormattedTypography
          containerClassName={classes.headingWrapper}
          className={classes.headingBody}
          body="Delete Cohort"
        />,
        headingDivider: false,
        contentDivider: true,
        contentWrapper: classes.dialogcontentBox,
        breakpoint: 'md',
        primaryBtnText: 'Delete',
        primaryBtnProps: {
          onClick: () => { },
        },
        secondaryBtnText: 'Cancel',
        secondaryBtnProps: {
          onClick: () => { setModalState(null); },
        },
      },
      props: {
        waveTitle: '',
      },
    },
    DELETEVALIDATION: {
      component: DeleteWaveModal,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: <FormattedTypography
          containerClassName={classes.headingWrapper}
          className={classes.headingBody}
          body=""
          subHeadingSection={classes.subHeadingSection}
          subHeading=""
        />,
        headingDivider: false,
        contentDivider: true,
        contentWrapper: classes.dialogcontentBoxForValidation,
        breakpoint: 'md',
        primaryBtnText: 'Close',
        primaryBtnProps: {
          onClick: () => { setModalState(null); },
        },
      },
      props: {
        waveTitle: '',
      },
    },
    VALIDATION: {
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
    COMPONENTDUEDATE: {
      component: BlankDueDateWarning,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: <FormattedTypography
          containerClassName={classes.headingWrapper}
          className={classes.headingBody}
          body="Please note"
        />,
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnText: 'Proceed to Save',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        secondaryBtnText: 'Cancel',
        secondaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
    DEFAULTCOHORT: {
      component: SetDefaultEnrollmentCohort,
      wrapperProps: {
        logo: null,
        heading: <FormattedTypography
          containerClassName={classes.headingWrapper}
          className={classes.headingBody}
          body="Set selected cohort as the Default Cohort"
        />,
        contentDivider: true,
        headingDivider: true,
        breakpoint: 'sm',
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnText: 'Confirm',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        secondaryBtnText: 'Cancel',
        secondaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
  });

  const fetchWaves = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getWaves(programId);
      if (!resp.data.success) throw new Error();
      batchUpdates(() => {
        const sortedData = resp?.data?.data
          // eslint-disable-next-line no-bitwise
          .sort((a, b) => (!(a.is_default ^ b.is_default) ? 0 : a.is_default ? -1 : 1));
        setWaveTableData(sortedData);
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, [programId]);

  const toArrayOfObject = (arr) => arr.map((a) => ({ value: a.id, label: `${a.client_id} - ${a.client_name}` }));

  const getClientId = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await fetchClientId();
      if (!resp.data.success) throw new Error();
      batchUpdates(() => {
        setClientIdList(toArrayOfObject(resp?.data?.data));
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const transferUserTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.user_management.transfer_cohort_users.transaction_ids',
      null,
    );
    const ecUserManagementTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.user_management.ec_users_management.transaction_ids',
      null,
    );
    const bulkEditPasswordTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.user_management.bulk_edit_user_password.transaction_ids',
      null,
    );
    if (transferUserTransactionIds && transferUserTransactionIds[0]) {
      setTransactionId(transferUserTransactionIds[0]);
    }
    if (ecUserManagementTransactionIds && ecUserManagementTransactionIds[0]) {
      setTransactionId(ecUserManagementTransactionIds[0]);
    }
    if (bulkEditPasswordTransactionIds && bulkEditPasswordTransactionIds[0]) {
      setTransactionId(bulkEditPasswordTransactionIds[0]);
    }
  }, [programMetadata]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(transactionId);
        const { done } = res.data;
        if (done) {
          fetchWaves();
          batchUpdates(() => {
            setAction(programAction[res.data.program_sub_type]);
            setProgress(res.data);
            setIsButtonDisabled(false);
            setTransactionId(null);
          });
        } else {
          batchUpdates(() => {
            setAction(programAction[res.data.program_sub_type]);
            setProgress(res.data);
            setIsButtonDisabled(true);
            setShowElement(true);
            if (res.data.status === QUEUED && !userCloseModal) {
              setOpenStatusModal(true);
            }
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (transactionId) {
      pollProgressApi();
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, userCloseModal]);

  useEffect(() => {
    let timer = '';
    if (progress?.done) {
      timer = setTimeout(() => {
        setShowElement(false);
        if (openStatusModal === false) {
          setProgress({ done: null, percentage: 0 });
        }
      }, 6000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [progress, openStatusModal]);

  useEffect(() => {
    setIsLoading(true);
    fetchWaves();
  }, [fetchWaves]);

  const addNewWave = useCallback(() => {
    getClientId();
    batchUpdates(() => {
      setcreateWaveModalState(true);
      setWaveTitleUniqueError(false);
      setIsCohortEdit(false);
    });
  }, [getClientId]);

  const preCloseModal = () => {
    batchUpdates(() => {
      setcreateWaveModalState(false);
      setIsCohortEdit(false);
      setCohortData([]);
      setisEditMetaDataModal(false);
      setEditMetaDataUsers([]);
    });
  };

  const editMetadata = (userList) => {
    setisEditMetaDataModal(true);
    setEditMetaDataUsers(userList);
  };

  const onCreateWave = async (values) => {
    setIsLoading(true);

    try {
      values.created_by = userInfo?.username || '';
      values.is_default = false;
      if (!waveTableData.length) {
        values.is_default = true;
      }
      const response = await createWave(programId, values);
      if (response && response.data && response.data.success) {
        fetchWaves();
        batchUpdates(() => {
          setIsLoading(false);
          setcreateWaveModalState(false);
          setWaveTitleUniqueError(false);
          setSuccess(true);
          setErrorMsg('Enrollment cohort added successfully');
          setSnackBarOpen(true);
        });
      }
    } catch (e) {
      batchUpdates(() => {
        setIsLoading(false);
        setErrorMsg('Unable to add the Enrollment cohort');
        setSnackBarOpen(true);
        setAlertMessage({
          type: 'error',
          message: e?.response?.data?.message || 'Unable to add the Enrollment cohort',
        });
        if (e?.response?.status === 409) {
          setWaveTitleUniqueError(true);
        }
      });
      console.error(e);
    }
  };

  const onCloseModal = () => {
    setModalState(null);
  };

  const onUpdateWave = async (values) => {
    setIsLoading(true);
    try {
      const response = await updateWave(programId, values);
      if (response && response.data && response.data.success) {
        fetchWaves();
        batchUpdates(() => {
          setIsLoading(false);
          setWaveTitleUniqueError(false);
          setcreateWaveModalState(false);
          setAlertMessage({
            type: 'success',
            message: `Waves '${values.wave_name}' updated successfully.`,
          });
          setSuccess(true);
          setErrorMsg('Cohort updated successfully');
          setSnackBarOpen(true);
          setIsCohortEdit(false);
        });
      }
    } catch (e) {
      batchUpdates(() => {
        setIsLoading(false);
        setErrorMsg('Enrollment cohort with this EC_code already exists');
        setSnackBarOpen(true);
        setAlertMessage({
          type: 'error',
          message: e?.response?.data?.message || 'Enrollment cohort with this EC_code already exists',
        });
        if (e?.response?.status === 409) {
          setWaveTitleUniqueError(true);
        }
      });
      console.error(e);
    }
  };

  const getDeleteValidationModalTitle = (wave) => {
    const title = {
      heading: '',
      subHeading: '',
    };
    if (waveTableData.length === 1) {
      title.heading = 'This cohort cannot be deleted';
      title.subHeading = 'At all times there must be at least one cohort within a learning plan.';
    } else if (wave.user_count) {
      title.heading = 'This cohort cannot be deleted as users exist in this cohort';
      title.subHeading = 'Users are tagged to this Enrollment Cohort. Please transfer them to another cohort and then try again.';
    } else if (wave.is_default) {
      title.heading = 'This cohort cannot be deleted as it is a default cohort';
      title.subHeading = 'Before retrying to delete this cohort, please set another enrollment cohort in this learning plan to the default';
    }
    return title;
  };

  const onConfirmDelete = async (wave) => {
    if (waveTableData.length === 1 || wave.user_count || wave.is_default) {
      batchUpdates(() => {
        setModalState('DELETEVALIDATION');
        setModalScheme((schema) => ({
          ...schema,
          DELETEVALIDATION: {
            ...schema.DELETEVALIDATION,
            wrapperProps: {
              ...schema.DELETEVALIDATION.wrapperProps,
              heading: <FormattedTypography
                containerClassName={classes.headingWrapper}
                className={classes.headingBody}
                body={getDeleteValidationModalTitle(wave).heading}
                subHeadingSection={classes.subHeadingSection}
                subHeading={getDeleteValidationModalTitle(wave).subHeading}
              />,
            },
            props: {
              ...schema.DELETEVALIDATION.props,
            },
          },
        }));
      });
    } else {
      try {
        setModalState(null);
        const response = await deleteCohort(wave.wave_id);
        if (response?.data) {
          fetchWaves();
          batchUpdates(() => {
            setSuccess(true);
            setErrorMsg(`${wave.ec_code} has been deleted successfully!`);
            setSnackBarOpen(true);
          });
        }
      } catch (e) {
        console.error(e);
        batchUpdates(() => {
          setErrorMsg(`Failed to delete ${wave.ec_code}. Please try again.`);
          setSnackBarOpen(true);
        });
      }
    }
  };

  const onDeleteWave = async (wave) => {
    batchUpdates(() => {
      setModalState('DELETE');
      setModalScheme((schema) => ({
        ...schema,
        DELETE: {
          ...schema.DELETE,
          wrapperProps: {
            ...schema.DELETE.wrapperProps,
            primaryBtnProps: {
              ...schema.DELETE.wrapperProps.primaryBtnProps,
              onClick: () => onConfirmDelete(wave),
            },
          },
          props: {
            ...schema.DELETE.props,
            waveTitle: wave.wave_name,
          },
        },
      }));
    });
  };

  const onEditWave = async (wave) => {
    getClientId();
    batchUpdates(() => {
      setcreateWaveModalState(true);
      setIsCohortEdit(true);
      setCohortData(wave);
      setWaveTitleUniqueError(false);
    });
  };

  const updateCohortUsersPassword = (cohort) => {
    setCohortData(cohort);
    setEditPasswordModal(true);
  };

  const onChangePassword = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        cohort_id: cohortData.wave_id,
        program_id: programId,
        password: data.password,
        force_password: data.forcePassword,
      };
      const response = await updateCohortPassword(payload);
      if (response?.data) {
        const tid = response?.data.transaction_id;
        batchUpdates(() => {
          setTransactionId(tid);
          setIsLoading(false);
          // setShowElement(true);
          setEditPasswordModal(false);
          setCohortData({});
          setAction(response?.data.program_sub_type || BULK_EDIT_USER_PASSWORD);
        });
      }
    } catch (e) {
      batchUpdates(() => {
        setErrorMsg('Failed to update user password for cohort. Please try again.');
        setSnackBarOpen(true);
        setIsLoading(false);
      });
    }
  };

  const updateCohort = async (defaultCohort, newCohort) => {
    try {
      setModalState(null);
      const data = {
        default_cohort_old: defaultCohort.wave_id,
        default_cohort_new: newCohort.wave_id,
      };
      const response = await updateDefaultCohort(programId, data);
      if (response?.data) {
        fetchWaves();
        batchUpdates(() => {
          setSuccess(true);
          setErrorMsg('Default cohort updated successfully!');
          setSnackBarOpen(true);
        });
      }
    } catch (e) {
      batchUpdates(() => {
        setErrorMsg('Failed to update default cohort. Please try again.');
        setSnackBarOpen(true);
      });
    }
  };

  const onSetDefaultCohort = (wave) => {
    const defaultCohort = waveTableData.filter((item) => item.is_default);
    batchUpdates(() => {
      setModalState('DEFAULTCOHORT');
      setModalScheme((schema) => ({
        ...schema,
        DEFAULTCOHORT: {
          ...schema.DEFAULTCOHORT,
          wrapperProps: {
            ...schema.DEFAULTCOHORT.wrapperProps,
            heading: <FormattedTypography
              containerClassName={classes.headingWrapper}
              className={classes.defaultCohortHeadingBody}
              body="Set selected cohort as the Default Cohort"
              subHeadingSection={classes.defaultCohortSubHeadingSection}
              subHeading={`Changing Default Cohort for ${configId}`}
            />,
            primaryBtnProps: {
              ...schema.DEFAULTCOHORT.wrapperProps.primaryBtnProps,
              onClick: () => updateCohort(defaultCohort[0], wave),
            },
          },
          props: {
            ...schema.DEFAULTCOHORT.props,
            cohortTitle: wave.ec_code,
            defaultCohortTitle: defaultCohort[0].ec_code,
          },
        },
      }));
    });
  };

  const transferUsers = useCallback(() => {
    history.push(`/${ROUTE_REPORT}/${programId}/${WAVE_MANAGEMENT}?transferUsers=true`);
  }, [history, programId]);

  const onSelectWave = async (wave) => {
    history.push(
      `/${ROUTE_REPORT}/${programId}/${WAVE_MANAGEMENT}/${wave.wave_id}`,
    );
  };
  const resetFileUpload = useCallback(
    (e) => {
      e.target.value = '';
    },
    [],
  );

  const onDownloadMetaData = async () => {
    try {
      await downloadEnrollMentMetaData(waveId);
    } catch (e) {
      const response = await blobToJson(e.response?.data);

      batchUpdates(() => {
        setErrorMsg(response.message);
        setSnackBarOpen(true);
      });
    }
  };

  const downloadUntaggedUsers = async () => {
    try {
      await getUntaggedUser(waveId);
    } catch (e) {
      const response = await blobToJson(e.response?.data);

      batchUpdates(() => {
        setErrorMsg(response.message);
        setSnackBarOpen(true);
      });
    }
  };

  const updateComponentDueDate = useCallback(async (componentData) => {
    try {
      const payloadData = componentData.map(
        (item) => ({ course_id: item.id, due_date: item.due_date ? format(new Date(item.due_date), 'dd-MM-yyyy') : null }),
      );

      const response = await postComponentDueDateApi(waveId, { components: payloadData });
      if (response?.data) {
        batchUpdates(() => {
          setSuccess(true);
          setErrorMsg('Component data edited successfully!');
          setSnackBarOpen(true);
          setModalState(null);
          setIsComponentEdit(false);
        });
      }
    } catch (e) {
      console.error(e);
      batchUpdates(() => {
        setErrorMsg('Failed to edit component date. Please try again.');
        setSnackBarOpen(true);
        setModalState(null);
      });
    }
  }, [waveId]);

  const onUpdateMetaData = useCallback(async (updatedMetaData) => {
    try {
      setIsLoading(true);
      const userIdList = editMetadataUsers.map((item) => item.id);
      const data = {
        user_ids: userIdList,
        metadata_json: updatedMetaData,
      };
      const response = await updateCohortUserList(waveId, data);
      if (response?.data) {
        batchUpdates(() => {
          setIsLoading(false);
          setSuccess(true);
          setErrorMsg('Component metadata edited successfully!');
          setSnackBarOpen(true);
          setisEditMetaDataModal(false);
          setRefreshUserList(true);
        });
      }
    } catch (e) {
      console.error(e);
      batchUpdates(() => {
        setErrorMsg('Failed to update component metadata. Please try again.');
        setSnackBarOpen(true);
      });
    }
  }, [editMetadataUsers, waveId]);

  const componentDueDateSave = (componentData) => {
    const dueDateData = componentData.filter((item) => item.due_date);
    if (dueDateData.length !== componentData.length) {
      batchUpdates(() => {
        setModalState('COMPONENTDUEDATE');
        setModalScheme((schema) => ({
          ...schema,
          COMPONENTDUEDATE: {
            ...schema.COMPONENTDUEDATE,
            wrapperProps: {
              ...schema.COMPONENTDUEDATE.wrapperProps,
              primaryBtnProps: {
                ...schema.COMPONENTDUEDATE.wrapperProps.primaryBtnProps,
                onClick: () => updateComponentDueDate(componentData),
              },
            },
            props: {
              ...schema.COMPONENTDUEDATE.props,
              component: componentData,
            },
          },
        }));
      });
    } else {
      updateComponentDueDate(componentData);
    }
  };

  const uploadFile = useCallback(
    async (formData) => {
      let uploadResult;
      setModalState(null);

      formData.append('program_id', programId);
      if (waveId) {
        formData.append('wave_id', waveId);
      }
      try {
        uploadResult = await uploadXlsx(programSubType, formData);
      } catch (e) {
        internalServerErrorModalLogic(history, e, setIsErrorModalOpen, () => { });
        return;
      }
      const tid = uploadResult.data.transaction_id;
      batchUpdates(() => {
        setTransactionId(tid);
        setIsLoading(false);
        setShowElement(true);
      });
    },
    [history, programId, programSubType, waveId],
  );

  const onUpload = async (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    resetFileUpload(e);
    formData.append('file', selectedFile, selectedFile.name);
    let result;
    setIsLoading(true);
    setAction(enrollmentMetaData ? COHORT_USER_METADATA : TRANSFER_COHORT_USERS);
    try {
      result = await validateXLSXApiCall(programSubType, formData);
    } catch (err) {
      setIsLoading(false);
      internalServerErrorModalLogic(history, err, setIsErrorModalOpen, resetFileUpload);
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);
    const errorMessageCount = errorMessages.errors?.length || 0;
    const [extension, ...nameParts] = selectedFile.name.split('.').reverse();
    batchUpdates(() => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          wrapperProps: {
            ...schema.VALIDATION.wrapperProps,
            heading: (
              <FormattedTypography
                prefix="Scanning file&nbsp;"
                body={nameParts.join('.')}
                suffix={`.${extension}`}
                subHeading={`${zeroAppender(
                  errorMessageCount,
                )} errors found. Please make the required changes and re-upload your file.`}
              />
            ),
          },
          props: errorMessages,
        },
      }));
    });
    if (errorMessages.errors.length) {
      setIsLoading(false);
      setModalState('VALIDATION');
      return;
    }
    await uploadFile(formData);
  };

  const onAlertClose = () => {
    setSnackBarOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setErrorMsg('Unable to connect to Docebo. Please try later.');
    }, 100);
  };

  const checkUserExistOnClient = async (client_id, cohort_id) => {
    try {
      const response = await checkUsersOnClientId(cohort_id, client_id);
      if (response?.data.success === false) {
        setSnackBarOpen(true);
        setSuccess(false);
        setErrorMsg('Client cannot be removed as user(s) are mapped to this client.');
      }
      return response;
    } catch (err) {
      console.error(err);
      // If needed, handle specific error states or rethrow the error
    }
    return {}; // Return a default response if there's an error
  };

  const alertBarText = (name) => ({
    PROCESSING: {
      [COHORT_USER_METADATA]: `Uploading enrollment metadata file "${name}"`,
      [TRANSFER_COHORT_USERS]: `Uploading transfer users file "${name}"`,
      [BULK_EDIT_USER_PASSWORD]: `Updating password for users in cohort "${name}"`,
    },
    COMPLETED: {
      [COHORT_USER_METADATA]: `Enrollment metadata file "${name}" was successfully uploaded`,
      [TRANSFER_COHORT_USERS]: `Transfer users file "${name}" was successfully uploaded`,
      [BULK_EDIT_USER_PASSWORD]: `Password for users in this cohort "${name}" has been updated successfully!`,
    },
    FAILED: {
      [COHORT_USER_METADATA]: `Failed to upload enrollment metadata file "${name}". Please try again.`,
      [TRANSFER_COHORT_USERS]: `Failed to upload transfer users file "${name}". Please try again.`,
      [BULK_EDIT_USER_PASSWORD]: `Failed to update password for users in this cohort "${name}". Please try again.`,
    },
    COMPLETED_WITH_ERRORS: {
      [COHORT_USER_METADATA]: `Enrollment metadata file "${name}" upload was completed with errors.`,
      [TRANSFER_COHORT_USERS]: `Transfer users file "${name}" upload was completed with errors.`,
      [BULK_EDIT_USER_PASSWORD]: `Password for users in this cohort "${name}" has been completed with errors.`,
    },
  });

  const AlertComp = () => {
    const name = progress?.file_name || progress?.progesskey;
    if (!progress?.done && progress?.done !== null) {
      const showProgress = progress?.program_sub_type === COHORT_USER_METADATA;
      return (
        <AlertBarWithAction
          variant={`${showProgress ? 'loading-without-percentage' : 'with-percentage'}`}
          percentage={!progress?.done && !showProgress ? progress?.percentage : null}
          labelText={alertBarText(name).PROCESSING[action]}
          actionButtonText="View Status"
          onActionClick={() => setOpenStatusModal(true)}
          customStyle={classes.alertBarStyle}
        />
      );
    }
    if (progress?.done && !isButtonDisabled) {
      if ([FAILED].includes(progress?.status)) {
        return (showElement
          ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={alertBarText(name).FAILED[action]}
              actionButtonText="View Details"
              onActionClick={() => setOpenStatusModal(true)}
              customStyle={classes.alertBarStyle}
            />
          ) : null
        );
      } if ([COMPLETED_WITH_ERRORS].includes(progress?.status)) {
        return (showElement
          ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={alertBarText(name).COMPLETED_WITH_ERRORS[action]}
              actionButtonText="View Details"
              onActionClick={() => setOpenStatusModal(true)}
              customStyle={classes.alertBarStyle}
            />
          ) : null
        );
      }
      return (showElement
        ? (
          <AlertBarWithAction
            variant="success"
            percentage={null}
            labelText={alertBarText(name).COMPLETED[action]}
            customStyle={classes.alertBarStyle}
            actionButtonIcon={
              <CloseIcon onClick={() => setShowElement(false)} />
            }
          />
        ) : null
      );
    }
    return <></>;
  };

  const renderPage = () => {
    if (enrollmentMetaData) {
      return (
        <EnrollmentMetaData
          programId={programId}
          waveId={waveId}
          onUploadMetaData={onUpload}
          isButtonDisabled={isButtonDisabled}
          onDownloadMetaData={onDownloadMetaData}
        />
      );
    }
    if (!enrollmentMetaData && waveId) {
      return (
        <CohortDetails
          programId={programId}
          waveId={waveId}
          componentDueDateSave={componentDueDateSave}
          isComponentEdit={isComponentEdit}
          setIsComponentEdit={setIsComponentEdit}
          editMetadata={editMetadata}
          refreshUserList={refreshUserList}
          setRefreshUserList={setRefreshUserList}
          downloadUntaggedUsers={downloadUntaggedUsers}
        />
      );
    }
    if (tranferUsersComp === 'true') {
      return (
        <UserListWaveTransfer
          onUpload={onUpload}
          programId={programId}
          isButtonDisabled={isButtonDisabled}
        />
      );
    }
    return (
      <WaveManagement
        addNewWave={addNewWave}
        transferUsers={transferUsers}
        waveTableData={waveTableData}
        onDeleteWave={onDeleteWave}
        onEditWave={onEditWave}
        onSetDefaultCohort={onSetDefaultCohort}
        updateCohortUsersPassword={updateCohortUsersPassword}
        onSelectedWave={onSelectWave}
        isLoading={isLoading}
        isButtonDisabled={isButtonDisabled}
        fetchWaves={fetchWaves}
      />
    );
  };

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Box className={classes.wrapper}>
      {progress && !openStatusModal ? <AlertComp /> : null}
      {renderPage()}
      {
        createWaveModalState && (
          isCohortEdit
            ? (
              <EditCohort
                open={createWaveModalState}
                onClose={preCloseModal}
                isCohortEdit={isCohortEdit}
                cohortData={cohortData}
                isLoading={isLoading}
                onCreateWave={onCreateWave}
                onUpdateWave={onUpdateWave}
                waveTitleUniqueError={waveTitleUniqueError}
                alertMessage={alertMessage}
                setAlertMessage={setAlertMessage}
                clientIdList={clientIdList}
                isAcademyGo={isAcademyGo}
                checkUserExistOnClient={checkUserExistOnClient}
              />
            )
            : (
              <AddCohortModal
                open={createWaveModalState}
                onClose={preCloseModal}
                isCohortEdit={isCohortEdit}
                cohortData={cohortData}
                isLoading={isLoading}
                onCreateWave={onCreateWave}
                onUpdateWave={onUpdateWave}
                waveTitleUniqueError={waveTitleUniqueError}
                alertMessage={alertMessage}
                setAlertMessage={setAlertMessage}
                clientIdList={clientIdList}
                isAcademyGo={isAcademyGo}
              />
            )
        )
      }
      {
        isEditMetaDataModal && (
          <EditMetadata
            open={isEditMetaDataModal}
            onClose={preCloseModal}
            editMetadataUsers={editMetadataUsers}
            isLoading={isLoading}
            onUpdateMetaData={onUpdateMetaData}
          />
        )
      }
      {
        editPasswordModal && (
          <UpdatePasswordModal
            loadingText="Saving user password..."
            open={editPasswordModal}
            onClose={() => setEditPasswordModal(false)}
            onChangePassword={onChangePassword}
            isLoading={isLoading}
          />
        )
      }

      {modalState && (
        <CustomUploadModal open onClose={onCloseModal} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={onAlertClose}
          severity={isSuccess ? 'success' : 'error'}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      {openStatusModal ? (
        <TransferStatusModal
          open={openStatusModal}
          onClose={() => {
            setOpenStatusModal(false);
            setUserCloseModal(true);
            setProgress({ done: null, percentage: 0 });
          }}
          progress={progress}
          programId={programId}
          action={action}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </Box>
  );
};

WaveManagementPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
    is_academy_go: PropTypes.bool.isRequired,
  }).isRequired,
};

export default WaveManagementPage;
