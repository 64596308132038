import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import _find from 'lodash/find';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import moment from 'moment';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { CancelToken, isCancel } from 'axios';

import { ContentDetailsSection, ContentDetailsLabel } from './ContentDetailsSection';
import ContentDetailsBlock from './ContentDetailsBlock';
import {
  COMPONENT_CODE_MAX_LIMIT, COMPONENT_TITLE_MAX_LIMIT,
  MASTER_ID_FOUND, PROJECT_ID_FAILED, PROJECT_ID_INVALID, PROJECT_ID_NOT_FOUND, PROJECT_ID_VALID,
} from '../../constants';
import { getPortfolioOfferingProgram, getSkillData } from '../../containers/common/apis';
import { toArrayOfObject, getSquareBracketsString } from '../../helpers/utils';
import { checkValidityOfElucidatPid } from '../../containers/ReplicationPage/api';

const useStyles = makeStyles({
  divider: {
    width: '98%',
    marginBottom: ({ hasContentHeader }) => (hasContentHeader ? '0rem' : '2rem'),
  },
});

const ContentSettingDetailsPage = ({
  isEdit, values, errors, touched, handleChange, setFieldValue, languages, setErrors,
  isValidating, setIsValidating, is_skill_lp,
}) => {
  const classes = useStyles();

  const helperTextLookup = {
    [PROJECT_ID_VALID]: 'Project ID is valid',
    [PROJECT_ID_FAILED]: 'Failed to contact Elucidat servers',
    [PROJECT_ID_INVALID]: 'Invalid Project Id',
    [PROJECT_ID_NOT_FOUND]: 'Project Id not found',
    [MASTER_ID_FOUND]: 'Please enter an ID that is NOT a Master Project on Elucidat',
  };

  const getadditionalFieldName = (field) => (`additional_fields[${field}].value`);

  const getadditionalFieldValue = (field) => (values?.additional_fields?.[field]?.value);

  const getadditionalFieldError = (field) => (errors?.additional_fields?.[field]?.value);

  const getadditionalFieldTouched = (field) => (touched?.additional_fields?.[field]?.value);

  const dropdownRef = useRef({});
  dropdownRef.current = {};
  const [portfolioOption, setPortfolioOptions] = useState([]);
  const [offeringOption, setOfferingOptions] = useState([]);
  const [programOption, setProgramOptions] = useState([]);
  const [skillOption, setSkillOption] = useState([]);
  const [status, setStatus] = useState('');
  const cancelTokenSource = {};

  const onDropdownChange = (key, val) => {
    if (val !== getadditionalFieldValue('Portfolio') && key === 'Portfolio') {
      if (dropdownRef.current.Offering) {
        dropdownRef.current.Offering.select.clearValue();
      }
      if (dropdownRef.current.Program) {
        dropdownRef.current.Program.select.clearValue();
      }
      batchUpdates(() => {
        setFieldValue(getadditionalFieldName(key), val);
        setOfferingOptions([]);
        setProgramOptions([]);
        setFieldValue(getadditionalFieldName('Offering'), '');
        setFieldValue(getadditionalFieldName('Program'), '');
      });
    } else if (val !== getadditionalFieldValue('Offering') && key === 'Offering') {
      if (dropdownRef.current.Program) {
        dropdownRef.current.Program.select.clearValue();
      }
      batchUpdates(() => {
        setProgramOptions([]);
        setFieldValue(getadditionalFieldName(key), val);
        setFieldValue(getadditionalFieldName('Program'), '');
      });
    } else {
      setFieldValue(getadditionalFieldName(key), val);
    }
  };

  const addToRefs = (el, key) => {
    if (el && !dropdownRef.current[key]) {
      dropdownRef.current[key] = el;
    }
  };

  const fetchSkillPrograms = async () => {
    const response = await getSkillData();
    const optionArray = _get(response, 'data.data', []);
    setSkillOption(toArrayOfObject(optionArray, { key: 'id', value: ['name', 'version'] }));
  };

  const fetchPortfolioOfferingProgram = async (data) => {
    const response = await getPortfolioOfferingProgram(data);
    const optionArray = _get(response, 'data.data', []);
    if (!data) {
      setPortfolioOptions(toArrayOfObject(optionArray));
    } else if (Object.keys(data).length === 1) {
      setOfferingOptions(toArrayOfObject(optionArray));
    } else {
      setProgramOptions(toArrayOfObject(optionArray));
    }
  };

  const portfolioValue = getadditionalFieldValue('Portfolio');
  const offeringValue = getadditionalFieldValue('Offering');

  useEffect(() => {
    if (is_skill_lp) {
      fetchSkillPrograms();
    }
  }, [is_skill_lp]);

  useEffect(() => {
    if (isEdit) {
      fetchPortfolioOfferingProgram();
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && portfolioValue) {
      fetchPortfolioOfferingProgram({ portfolio: portfolioValue });
    }
  }, [isEdit, portfolioValue]);

  useEffect(() => {
    if (isEdit && portfolioValue && offeringValue) {
      fetchPortfolioOfferingProgram({ portfolio: portfolioValue, offering: offeringValue });
    }
  }, [isEdit, portfolioValue, offeringValue]);

  const checkPIdValidity = useCallback(
    async (pid) => {
      // Cancel old request
      setIsValidating(true);
      if (cancelTokenSource[['elucidat-project-id']]) {
        cancelTokenSource[['elucidat-project-id']].cancel();
      }

      setErrors({ 'elucidat-project-id': '' });
      setStatus('');

      if (!pid) {
        setIsValidating(false);
        return;
      }

      if (!new RegExp('^[a-z0-9;]+$', 'i').test(pid)) {
        setErrors({ 'elucidat-project-id': 'Special characters are not allowed' });
        setIsValidating(false);
        return;
      }

      try {
        cancelTokenSource[['elucidat-project-id']] = CancelToken.source();
        const response = await checkValidityOfElucidatPid(pid, cancelTokenSource[['elucidat-project-id']].token);

        // validate master project id and project id in general.
        if (response?.data?.is_master_project) {
          setErrors({ 'elucidat-project-id': helperTextLookup[MASTER_ID_FOUND] });
        } else if (response?.data?.valid) {
          setStatus(`${helperTextLookup[PROJECT_ID_VALID]}`);
        } else {
          setErrors({ 'elucidat-project-id': helperTextLookup[PROJECT_ID_INVALID] });
        }

        setIsValidating(false);
      } catch (e) {
        if (!isCancel(e)) {
          setErrors({ 'elucidat-project-id': 'Something went wrong, Try again' });
          setIsValidating(false);
        }
      }
    },
    [cancelTokenSource, helperTextLookup, setErrors, setIsValidating],
  );

  const debouncedChange = useCallback(_debounce(checkPIdValidity, 500), [checkPIdValidity]);

  const onChange = useCallback(
    (e) => {
      handleChange(e);
      debouncedChange(e.target.value);
    },
    [debouncedChange, handleChange],
  );
  return (
    <Grid container item spacing={2}>
      <ContentDetailsBlock headerText="Component Details">
        <ContentDetailsSection
          label="Title"
          name="name"
          type="text"
          required
          handleChange={(e) => {
            if (e.target.value.length <= COMPONENT_TITLE_MAX_LIMIT) {
              handleChange(e);
            }
          }}
          isEdit={isEdit}
          fieldType="text"
          value={values.name}
          hasError={touched.name && !!errors.name}
          errorText={touched.name && errors.name}
          showTextLimit
          maxTextLimit={COMPONENT_TITLE_MAX_LIMIT}
        />
        <ContentDetailsSection
          label="Code"
          name="code"
          type="text"
          required
          isEdit={isEdit}
          fieldType="text"
          handleChange={(e) => {
            if (e.target.value.length <= COMPONENT_CODE_MAX_LIMIT) {
              handleChange(e);
            }
          }}
          value={values.code}
          hasError={touched.code && !!errors.code}
          errorText={touched.code && errors.code}
          showTextLimit
          maxTextLimit={COMPONENT_CODE_MAX_LIMIT}
        />
        <ContentDetailsSection
          label="Description"
          name="description"
          type="text"
          required
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={values.description}
          hasError={touched.description && !!errors.description}
          errorText={touched.description && errors.description}
        />
        <ContentDetailsSection label="Thumbnail" type="image" value={values.thumbnail} />
        <ContentDetailsSection label="ID" type="text" value={values.uidCourse} />
        <ContentDetailsSection label="Type" type="chip" value={values.type} />
      </ContentDetailsBlock>
      <ContentDetailsBlock headerText="Settings">
        <ContentDetailsSection
          label="Language"
          name="lang_label"
          type="text"
          fieldType="select"
          isEdit={isEdit}
          handleChange={(n, v, b) => {
            setFieldValue(n, v, b);
            setFieldValue('lang_code', _find(languages, { label: v }).code, b);
          }}
          value={values.lang_label}
          options={languages}
        />

        {!(values?.type?.includes('classroom')) && (
          <>
            <ContentDetailsSection
              label="Average Time"
              infoText="Define average time for the component"
              name="duration"
              type="text"
              fieldType="text"
              disabled={moment() < moment(values.date_end)}
              isEdit={isEdit}
              handleChange={handleChange}
              value={values.duration}
              hasError={touched.duration && !!errors.duration}
              errorText={touched.duration && errors.duration}
            />
            <Divider className={classes.divider} variant="middle" light />
            <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
              <ContentDetailsLabel label="Component Validity" />
            </Grid>
            <ContentDetailsSection
              label="Start Date"
              infoText="Learners will be able to access the course from the selected date onward, provided the component is in Published status"
              type="text"
              value={values.date_begin}
              name="date_begin"
              fieldType="calendar"
              isEdit={isEdit}
              hasError={touched.date_begin && !!errors.date_begin}
              errorText={touched.date_begin && errors.date_begin}
              handleChange={setFieldValue}
            />
            <ContentDetailsSection
              label="End Date"
              infoText="Learners will be able to access the course up to the selected date, provided the component is in Published status"
              name="date_end"
              type="text"
              value={values.date_end}
              fieldType="calendar"
              isEdit={isEdit}
              hasError={touched.date_end && !!errors.date_end}
              errorText={touched.date_end && errors.date_end}
              handleChange={setFieldValue}
            />
            {values.soft_deadline !== null && values.date_end && (
              <ContentDetailsSection
                label="Soft Deadline"
                name="soft_deadline"
                type="text"
                fieldType="switch"
                value={values.soft_deadline}
                isEdit={isEdit}
                handleChange={setFieldValue}
                alertText={values?.soft_deadline ? 'Learners can access the component training material after the component end date' : 'Learners cannot access the component training material after the component end date'}
              />
            )}
            <Divider className={classes.divider} variant="middle" light />
          </>
        )}
        <ContentDetailsSection
          label="Credits (CEUs)"
          infoText="Set the number of credits assigned to this course (greater than 0)"
          name="credits"
          type="text"
          value={values.credits}
          fieldType="text"
          isEdit={isEdit}
          handleChange={handleChange}
          hasError={touched.credits && !!errors.credits}
          errorText={touched.credits && errors.credits}
        />
        <ContentDetailsSection
          label="EOP Feedback Component (For Impact Tracking)"
          name="eop"
          type="text"
          infoText="Indicates this is/is not an end-of-program (EOP) feedback survey. This allows Impact Tracking team to gather impact metrics Academy-wide"
          value={values.eop ? values.eop : 0}
          fieldType="switch"
          isEdit={false}
        />
      </ContentDetailsBlock>
      <ContentDetailsBlock headerText="Additional Details">
        <ContentDetailsSection
          label="Portfolio"
          name={getadditionalFieldName('Portfolio')}
          required
          type="text"
          fieldType="select"
          options={portfolioOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Portfolio')}
          value={getadditionalFieldValue('Portfolio')}
          isEdit={isEdit}
          hasError={getadditionalFieldTouched('Portfolio')
            && !!getadditionalFieldError('Portfolio')}
          errorText={getadditionalFieldTouched('Portfolio')
            && getadditionalFieldError('Portfolio')}
        />
        <ContentDetailsSection
          label="Offering"
          name={getadditionalFieldName('Offering')}
          required
          type="text"
          fieldType="select"
          options={offeringOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Offering')}
          value={getadditionalFieldValue('Offering')}
          isEdit={isEdit}
          hasError={getadditionalFieldTouched('Offering')
            && !!getadditionalFieldError('Offering')}
          errorText={getadditionalFieldTouched('Offering')
            && getadditionalFieldError('Offering')}
        />
        <ContentDetailsSection
          label="Program"
          name={getadditionalFieldName('Program')}
          options={programOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Program')}
          value={getadditionalFieldValue('Program')}
          type="text"
          fieldType="select"
          isEdit={isEdit}
        />
        <ContentDetailsSection
          label="Elucidat Project ID"
          name={getadditionalFieldName('Elucidat Project ID')}
          type="text"
          value={getadditionalFieldValue('Elucidat Project ID')}
          fieldType="text"
          isEdit={isEdit}
          handleChange={onChange}
          hasError={!!errors['elucidat-project-id']}
          errorText={errors['elucidat-project-id']}
          isValidating={isValidating}
          status={status}
        />
        <ContentDetailsSection
          label="Component Name"
          name={getadditionalFieldName('Course Name')}
          type="text"
          value={getadditionalFieldValue('Course Name')}
          fieldType="text"
          isEdit={isEdit}
          handleChange={handleChange}
        />
        <Divider className={classes.divider} variant="middle" light />
        <ContentDetailsSection
          label="Component Runner"
          name={getadditionalFieldName('Course runner')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Course runner')}
          hasError={getadditionalFieldTouched('Course runner')
            && !!getadditionalFieldError('Course runner')}
          errorText={getadditionalFieldTouched('Course runner')
            && getadditionalFieldError('Course runner')}
        />
        <ContentDetailsSection
          label="Additional Metadata"
          name={getadditionalFieldName('Additional Metadata')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Additional Metadata')}
        />
        <ContentDetailsSection
          label="Keep in mind"
          name={getadditionalFieldName('Keep in mind')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Keep in mind')}
        />
        <ContentDetailsSection
          label="After you complete the component"
          name={getadditionalFieldName('After you complete this course')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('After you complete this course')}
        />
        {
          is_skill_lp && (
            <>
              <ContentDetailsSection
                label="Skill Name"
                name={'skill_id'}
                required
                type="text"
                fieldType="select"
                options={skillOption}
                handleChange={(n, v, b) => {
                  setFieldValue(n, v, b);
                  setFieldValue('skill_alias', _find(skillOption, { value: v })?.label.split(' - ')[0], b);
                }}
                addToRefs={(el) => addToRefs(el, 'Skill')}
                value={values?.skill_id
                  ? _find(skillOption, { value: values.skill_id })?.label
                  : null}
                isEdit={isEdit}
                hasError={touched.skill_id && !!errors.skill_id}
                errorText={touched.skill_id && errors.skill_id}
              />
              <ContentDetailsSection
                label="Skill Alias"
                name={'skill_alias'}
                type="text"
                required
                isEdit={isEdit}
                fieldType="textarea"
                handleChange={handleChange}
                value={values?.skill_alias ?? null}
                hasError={touched.skill_alias && !!errors.skill_alias}
                errorText={touched.skill_alias && errors.skill_alias}
              />
            </>
          )
        }

      </ContentDetailsBlock>
    </Grid>
  );
};

ContentSettingDetailsPage.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  isValidating: PropTypes.bool.isRequired,
  setIsValidating: PropTypes.func.isRequired,
  is_skill_lp: PropTypes.bool.isRequired,
};

export default ContentSettingDetailsPage;
