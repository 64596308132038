import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import qs from 'query-string';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {
  CONTENT_TYPE_MAPPING, USER_ACCESS_MAPPING, ROUTE_REPORT,
  AUTO_HIDE_DURATION, COURSE_OPS, IMPORT_COURSE, ROLE_OPS,
} from '../../constants';
import {
  fetchContentApi, cloneCourseById, downloadFile, getScormReleaseDetails, refreshScorm, updateScorm,
  assignOrRemoveEOP, getBulkScormTMs, postBulkScormTMs, triggerImport, bulkUpdateComponentStatus,
  reorderComponents,
} from './apis';

import { reportPageDateTimeFormatter, zeroAppender } from '../../helpers/formattingHelpers';
import ContentManagementList from '../../components/ContentManagement/ContentManagementList';
import ContentManagementView from '../../components/ContentManagement/ContentManagementView';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';
import CopyComponentReportModal from '../../components/ContentManagement/CopyComponentReportModal';
import ImportComponentCopy from '../../components/ContentManagement/ImportComponentCopy';
import BulkScormRelease from '../../components/ContentManagement/BulkScormRelease';
import {
  getReport, getProgress, downloadFile as importDownloadFile,
} from '../common/apis';
import useProgressResult from '../../hooks/useProgressResult';
import {
  objectHasData,
} from '../../helpers/utils';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import useNotifications from '../../hooks/useNotifications';
import { getErrorMessage } from '../../helpers/apiHelper';
import ImportLogStatuses from '../../components/ContentManagement/ImportComponentCopy/ImportLogStatuses';
import BreadCrumbHOC from '../../components/BreadCrumbHOC';
import MyContext from '../../context';

const ContentManagementPage = ({
  match, programMetadata, location, onProgramMetadataRefresh,
}) => {
  const programId = get(match, 'params.programId');
  const programSubType = get(match, 'params.programSubType');
  const { config_id: configId, docebo_lp_id: doceboLpId } = programMetadata;

  const [copyProcessing, setCopyProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [doceboUrl, setDoceboUrl] = useState('');
  const [metadata, setMetadata] = useState({
    totalComponents: '',
    eLearningComponents: '',
    workshopComponents: '',
    lastRefreshed: '',
  });
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState('error');
  const [snackBarMsg, setSnackBarMsg] = useState('Please try later.');
  const [copiedComponentStatus, setCopiedComponentStatus] = useState('');
  const [copiedComponentData, setCopiedComponentData] = useState({
    new_component_title: '',
    new_component_code: '',
  });
  const [copiedTransactionId, setCopiedTransactionId] = useState(undefined);
  const [viewDetailsModal, setviewDetailsModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [bulkScormTms, setBulkScormTms] = useState({});
  const [importTransactionId, setImportTransactionId] = useState(undefined);
  const [pollProgressValue, setPollProgressValue] = useState(false);
  const [showImportElement, setShowImportElement] = useState(false);
  const [eopComponent, setEopComponent] = useState({});
  const [importLoading, setImportLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [importModalState, setImportModalState] = useState('');
  const query = qs.parse(location.search);
  const featureName = get(query, 'feature', null);
  const courseViewId = get(query, 'id', 'null');
  const { notifyError, notifyInfo } = useNotifications();

  const ImportCourseTransactionIds = _get(
    programMetadata,
    `activities_status.${COURSE_OPS}.${IMPORT_COURSE}.transaction_ids`,
    [],
  );

  const statusOfLastTransaction = useCallback(async (latestTransactionId) => {
    const res = await getProgress(latestTransactionId);
    const { done } = res.data;
    if (!done) {
      setImportTransactionId(latestTransactionId);
    }
  }, []);

  useEffect(() => {
    if (ImportCourseTransactionIds.length) {
      statusOfLastTransaction(ImportCourseTransactionIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ImportCourseTransactionIds.length]);

  const secondsToHms = (seconds) => {
    const h = Math.floor(seconds / 3600);
    // eslint-disable-next-line no-mixed-operators
    const m = Math.floor(seconds % 3600 / 60);

    const hDisplay = `${h} h `;
    const mDisplay = m > 0 ? m + (m === 1 ? 'min' : ' mins') : '';
    return hDisplay + mDisplay;
  };

  const getModifiedTM = (trainingMaterials) => trainingMaterials.filter(
    (obj) => obj.type,
  ).map((obj, index) => ({
    ...obj,
    order: index + 1 > 9 ? index + 1 : `0${index + 1}`,
  }));

  const getUniqueTM = (trainingMaterials) => {
    const uniqueTm = [];
    trainingMaterials.forEach((tmObj) => {
      if (!uniqueTm.includes(tmObj.type)) {
        uniqueTm.push(tmObj.type);
      }
      if (!uniqueTm.includes(tmObj.sub_type)) {
        uniqueTm.push(tmObj.sub_type);
      }
    });
    return uniqueTm;
  };

  const getModifiedData = useCallback((data) => {
    const updatedData = [];
    data.forEach((contentObj, index) => {
      const userAccessObj = contentObj?.is_published ? USER_ACCESS_MAPPING.published
        : USER_ACCESS_MAPPING.locked;

      const training_materials = contentObj?.training_materials?.length
        ? getModifiedTM(contentObj.training_materials) : [];

      updatedData.push({
        ...contentObj,
        order: zeroAppender(index),
        component_title: contentObj.name,
        code: contentObj.course_code || '-',
        type: {
          value: contentObj.course_type,
          label: CONTENT_TYPE_MAPPING[contentObj.course_type].label,
          icon: CONTENT_TYPE_MAPPING[contentObj.course_type].icon,
        },
        no_of_tm: training_materials.length,
        estimated_time: contentObj.duration ? secondsToHms(contentObj.duration) : '-',
        docebo_id: contentObj?.id?.toString() || '-',
        user_access: {
          value: userAccessObj.value,
          label: userAccessObj.label,
          icon: userAccessObj.icon,
        },
        training_materials,
        unique_tm: contentObj.training_materials.length > 0
          ? getUniqueTM(contentObj.training_materials) : [],
      });
    });
    return updatedData;
  }, []);

  const updatePollProgress = useCallback((value) => {
    setPollProgressValue(value);
  }, []);

  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(
    pollProgressValue, importTransactionId, updatePollProgress,
  );

  const { data: progress } = objectHasData(progressResult) ? progressResult : { data: {} };

  useEffect(() => {
    if (importTransactionId) {
      updatePollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTransactionId]);

  const fetchContent = useCallback(async ({ isRefresh }) => {
    try {
      setIsLoading(true);
      const response = await fetchContentApi({ isRefresh, programId });
      let metadataObj = {
        totalComponents: '',
        eLearningComponents: '',
        workshopComponents: '',
        lastRefreshed: '',
      };
      if (response.data?.last_refreshed) {
        // eslint-disable-next-line max-len
        metadataObj.lastRefreshed = reportPageDateTimeFormatter(new Date(response.data.last_refreshed));
      }

      const dataLength = response?.data?.data?.length;
      let isDataEmpty = !dataLength;
      if (dataLength === 1) {
        const firstObj = response.data.data[0];
        isDataEmpty = firstObj && !firstObj.course_code && !firstObj.course_graphic
          && !firstObj.course_type && !firstObj.duration && !firstObj.id
          && !firstObj.is_published && !firstObj.name;
      }

      if (!isDataEmpty) {
        const data = get(response, 'data.data', []);
        const doceboBaseUrl = get(response, 'data.docebo_url', []);
        const modifiedData = data.length ? getModifiedData(data) : [];
        setTableData(modifiedData);
        setDoceboUrl(doceboBaseUrl);
        const workshopComponentsLength = filter(data, { course_type: 'classroom' }).length;
        metadataObj = {
          ...metadataObj,
          totalComponents: data.length,
          workshopComponents: workshopComponentsLength,
          eLearningComponents: data.length - workshopComponentsLength,
        };
      } else {
        setTableData([]);
      }
      setMetadata(metadataObj);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [getModifiedData, programId]);

  useEffect(() => {
    if (tableData.length > 0) {
      setEopComponent(_find(tableData, (o) => (o.eop === true)));
    }
  }, [tableData]);

  const openSnackBar = () => {
    setSnackBarOpen(true);
    setTimeout(() => {
      setSnackBarOpen(false);
    }, 20000);
  };

  useEffect(() => {
    if (progressError !== null) {
      updatePollProgress(false);
      notifyError(progressError);
      clearProgressResult();
      clearProgressError();
    }
    if (progress?.done) {
      batchUpdates(() => {
        updatePollProgress(false);
        setShowImportElement(true);
        fetchContent(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, progressError]);

  const clearsnackBarData = () => {
    batchUpdates(() => {
      setSnackBarMsg('');
      setCopiedComponentStatus('');
      setCopiedTransactionId(undefined);
      setCopiedComponentData({});
      setSnackBarSeverity('');
    });
  };

  const onDownloadLog = async () => {
    try {
      await downloadFile(copiedTransactionId, configId, programSubType, new Date());
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const onDownloadImportLog = async (transactionData) => {
    try {
      const tid = transactionData.transaction_id;
      const createdAt = transactionData.created_at;
      await importDownloadFile(tid, configId, IMPORT_COURSE, createdAt);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const triggerComponentImport = async (importData) => {
    try {
      setImportLoading(true);
      const resp = await triggerImport(importData);
      if (resp?.data?.success === true) {
        setImportTransactionId(resp?.data?.transaction_id);
        setCopiedComponentData({
          new_component_title: importData?.new_course_title,
          new_component_code: importData?.new_course_code,
        });
      } else {
        notifyInfo(resp?.data?.message);
      }
    } catch (e) {
      notifyError(getErrorMessage(e));
    } finally {
      setImportLoading(false);
      setOpenImportModal(false);
    }
  };

  const onCopyComponent = async (courseId, data) => {
    try {
      clearsnackBarData();
      setCopyProcessing(true);
      const resp = await cloneCourseById(courseId, programId, { ...data, lp_config_id: configId });
      if (resp.data.success === true) {
        setCopiedComponentData(data);
        fetchContent({ isRefresh: true }).then(() => {
          setSnackBarMsg(resp.data.message);
          setCopiedComponentStatus(resp.data.status);
          setSnackBarSeverity(resp.data.status === 'COMPLETED' ? 'success' : 'error');
          setCopiedTransactionId(resp.data.transaction_id);
          openSnackBar();
        }).catch((err) => {
          console.log(err);
        });
      } else {
        setSnackBarMsg(resp.data.message);
        setCopiedComponentStatus(resp.data.status);
        setCopiedTransactionId(resp.data.transaction_id);
        setSnackBarSeverity('error');
        openSnackBar();
      }
    } catch (err) {
      setSnackBarMsg(err.message);
      setSnackBarSeverity('error');
      openSnackBar();
    } finally {
      setCopyProcessing(false);
    }
  };

  const onViewDetailsClose = () => {
    setviewDetailsModal(false);
  };

  const assignOrRemoveEOPComponent = async (courseId, data) => {
    try {
      const resp = await assignOrRemoveEOP(courseId, programId, data);
      if (resp.data.success === true) {
        fetchContent({ isRefresh: true }).catch((err) => {
          console.log(err);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBulkScormTMs = async () => {
    setIsLoading(true);
    try {
      const resp = await getBulkScormTMs(programId);
      if (resp.data.success === true) {
        setBulkScormTms(resp.data.data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onViewStatus = useCallback((status) => {
    batchUpdates(() => {
      setInfoModal(true);
      setImportModalState(status);
    });
  }, []);

  const onInfoModalClose = () => {
    setInfoModal(false);
    setImportModalState('');
  };

  const beforeFetchContent = (data) => {
    clearsnackBarData();
    fetchContent(data);
  };

  useEffect(() => {
    if (featureName === null) {
      fetchContent({ isRefresh: false });
    }
  }, [featureName, fetchContent]);

  useEffect(() => {
    if (showImportElement) {
      setTimeout(() => {
        setShowImportElement(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showImportElement]);

  const { role } = useContext(MyContext);

  const isDDAdmin = role === ROLE_OPS;

  const navigationBreadcrumb = (value) => {
    const breadCrumbList = [
      { label: 'All Components', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${programSubType}`, isActive: false },
      { label: `${value}`, redirectionUrl: '', isActive: true },
    ];

    return (
      <Box style={{ padding: '2rem 0' }}>
        <BreadCrumbHOC list={breadCrumbList} />
      </Box>
    );
  };

  const ContentManagementFeatures = {
    'view-course': ContentManagementView,
    'bulk-scorm': BulkScormRelease,
  };

  const ContentManagement = ContentManagementFeatures[featureName] || ContentManagementList;

  return (
    <>
      {
        isSnackBarOpen && (
          <AlertBarWithAction
            variant={snackBarSeverity}
            labelText={snackBarMsg}
            actionButtonText={snackBarSeverity !== 'success' && copiedComponentStatus && 'View details'}
            actionButtonIcon={<CloseIcon onClick={() => setSnackBarOpen(false)} />}
            onActionClick={() => (copiedComponentStatus && setviewDetailsModal(true))}
          />
        )
      }
      {importTransactionId && Object.keys(progress).length > 0 ? (
        <Paper style={{ paddingBottom: '0.5rem' }}>
          <AlertReleaseInfo
            progress={progress}
            showElement={showImportElement}
            progressMessage={`Importing a component ${progress?.new_course_code} from ${progress?.source_lp_code}`}
            failedMessage={`${progress?.new_course_code} component was imported with errors in this Learning Plan`}
            withErrorsMessage={`${progress?.new_course_code} component was created but could not be imported to this Learning Plan. Please try to link it from Content Management on Docebo`}
            successMessage={`${progress?.new_course_code} component was successfully imported in this Learning Plan`}
            onViewStatus={onViewStatus}
            setShowElement={setShowImportElement}
          />
        </Paper>
      ) : null}
      <ContentManagement
        transactionId={courseViewId}
        match={match}
        doceboUrl={doceboUrl}
        doceboLpId={doceboLpId}
        configId={configId}
        getScormReleaseDetails={getScormReleaseDetails}
        updateScorm={updateScorm}
        refreshScorm={refreshScorm}
        location={location}
        isDDAdmin={isDDAdmin}
        navigationBreadcrumb={navigationBreadcrumb}
        programMetadata={programMetadata}
        onProgramMetadataRefresh={onProgramMetadataRefresh}
        fetchBulkScormTMs={fetchBulkScormTMs}
        bulkScormTms={bulkScormTms}
        programId={programId}
        postBulkScormTMs={postBulkScormTMs}
        isScormTmsLoading={isLoading}
        onCopyComponent={onCopyComponent}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        copyProcessing={copyProcessing}
        tableData={tableData}
        metadata={metadata}
        fetchContent={beforeFetchContent}
        copiedComponentData={copiedComponentData}
        openImportComponent={() => setOpenImportModal(true)}
        assignOrRemoveEOPComponent={assignOrRemoveEOPComponent}
        importInProgress={pollProgressValue}
        bulkUpdateComponentStatus={bulkUpdateComponentStatus}
        setTableData={setTableData}
        reorderComponents={reorderComponents}
      />
      {
        viewDetailsModal && (
          <CopyComponentReportModal
            open={viewDetailsModal}
            onClose={onViewDetailsClose}
            status={copiedComponentStatus}
            transactionId={copiedTransactionId}
            getReport={getReport}
            onDownloadLog={onDownloadLog}
          />
        )
      }
      {openImportModal && (
        <ImportComponentCopy
          open={openImportModal}
          onClose={() => setOpenImportModal(false)}
          programId={programId}
          targetLpCode={configId}
          triggerImport={triggerComponentImport}
          eopComponent={eopComponent}
          importLoading={importLoading}
          fetchContentApi={fetchContentApi}
        />
      )}
      {
        importTransactionId && infoModal && (
          <ImportLogStatuses
            open={infoModal}
            onClose={onInfoModalClose}
            modalState={importModalState}
            transactionId={importTransactionId}
            programId={programId}
            progressData={progress}
            progressError={progressError}
            getReport={getReport}
            onDownloadLog={onDownloadImportLog}
          />
        )
      }
    </>
  );
};

ContentManagementPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
      transactionId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
    docebo_lp_id: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
};

export default ContentManagementPage;
