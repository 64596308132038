import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fileValidate, fileUpload, clearMessage, clearTransactionId,
} from '../../store/actions/fileValidate';
import BulkUsersUpload from '../../components/PlatformLevelUsers/BulkUsersUpload';

const mapStateToProps = (state) => ({
  isLoading: state.FileValidateReducer.isLoading || state.FileUploadReducer.isLoading,
  fileValidateError: state.FileValidateReducer.errors,
  validateData: state.FileValidateReducer,
  message: state.FileValidateReducer.message,
  transaction_id: state.FileUploadReducer.transaction_id,
  upload_message: state.FileUploadReducer.message,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fileValidate,
    fileUpload,
    clearMessage,
    clearTransactionId,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BulkUsersUpload);
