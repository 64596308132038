import {
  put, call, takeLatest,
} from 'redux-saga/effects';

import {
  GET_PROGRAMS,
  GET_PROGRAMS_STARTED,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  GET_FILTERED_PROGRAMS,
  GET_FILTERED_PROGRAMS_SUCCESS,
  GET_INITIAL_PROGRAMS_STARTED,
} from '../../constants';
import { getAllPrograms, getAllFilteredPrograms } from '../../../containers/BasePage/apis';

function* getLearningPrograms({ payload }) {
  try {
    const {
      programType, searchText, page, stages,
    } = payload;
    const actionType = page === 0 ? GET_INITIAL_PROGRAMS_STARTED : GET_PROGRAMS_STARTED;
    yield put({ type: actionType });
    const result = yield call(getAllPrograms, programType, searchText, page, stages);
    if (!result.data.docebo_success) throw new Error();
    const { hasMore, totalRows, items } = result.data;
    yield put({
      type: GET_PROGRAMS_SUCCESS,
      data: {
        programs: items, totalPrograms: totalRows, hasMore, page: page + 1,
      },
    });
  } catch (err) {
    yield put({ type: GET_PROGRAMS_FAILURE, error: err });
  }
}

function* getFilteredLearningPrograms({ payload }) {
  try {
    const {
      filterParams, page,
    } = payload;
    const actionType = page === 1 ? GET_INITIAL_PROGRAMS_STARTED : GET_PROGRAMS_STARTED;
    yield put({ type: actionType });
    const result = yield call(getAllFilteredPrograms, page, filterParams);
    if (!result.data.docebo_success) throw new Error();
    const { hasMore, totalRows, items } = result.data;
    yield put({
      type: GET_FILTERED_PROGRAMS_SUCCESS,
      data: {
        programs: items, totalPrograms: totalRows, hasMore, page: page + 1,
      },
    });
  } catch (err) {
    yield put({ type: GET_PROGRAMS_FAILURE, error: err });
  }
}

export function* learningProgramsSaga() {
  yield takeLatest(GET_PROGRAMS, getLearningPrograms);
  yield takeLatest(GET_FILTERED_PROGRAMS, getFilteredLearningPrograms);
}

export default learningProgramsSaga;
