import {
  put, call, takeLatest, select,
} from 'redux-saga/effects';
import get from 'lodash/get';
import _update from 'lodash/update';
import {
  setLoading,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  updateUserDetailsStore,
  setPlatformDetailsLoader,
} from '../PlatformLevelUsers/userDetail';
import {
  fetchUserDetails,
  saveDoceboPlatformDetails,
} from '../async/platformUserAsync';
import { FETCH_LANGUAGES, FETCH_USER_DETAILS, SAVE_USER_DETAILS } from '../../constants';
import { fetchActiveLanguages, getExperienceTypeOptions } from '../../../containers/common/apis';

const toTitleCase = (str) => str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());

export function* getUserDetails({ data }) {
  try {
    yield put(setLoading(true));
    const response = yield call(fetchUserDetails, data);
    const updatedObject = _update(response, 'data.language', (l) => toTitleCase(l));
    yield put(getUserDetailsSuccess(updatedObject));
  } catch (err) {
    yield put(getUserDetailsFailure(err?.response?.data));
  }
}

export function* getUserLanguages() {
  try {
    const userDetailStore = yield select((state) => state.platformUserDetailsData);
    const { primaryExperienceOptions } = userDetailStore;
    // if dropdown options already exists then don't make API call
    if (!primaryExperienceOptions.length) {
      yield put(updateUserDetailsStore(
        { editDoceboDetails: true, isPlatformDetailsLoading: true },
      ));
      const response = yield call(fetchActiveLanguages);
      let languageOptions = get(response, 'data.languages', []);
      if (languageOptions) {
        languageOptions = Object.values(languageOptions).map((lang) => ({
          label: lang.description,
          value: lang.description,
          code: lang.code,
          shortCode: lang.browsercode,
        }));
      }

      const expTypeResult = yield call(getExperienceTypeOptions);
      const primaryExperienceList = expTypeResult.data.data.options
        .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
        .map((op) => ({ value: op, label: op }));

      yield put(updateUserDetailsStore(
        {
          languageOptions,
          primaryExperienceOptions: primaryExperienceList,
          isPlatformDetailsLoading: false,
        },
      ));
    } else {
      yield put(updateUserDetailsStore({ editDoceboDetails: true }));
    }
  } catch (err) {
    yield put(getUserDetailsFailure(err?.response?.data));
  }
}

export function* saveUserDetailsSaga({ payload }) {
  try {
    const {
      data, data: {
        primary_experience, language: { code, label }, password, force_password_change,
      }, userId,
    } = payload;

    const updatedPayload = {
      primary_experience,
      language_code: code,
      language_description: label,
      ...(password && { password }),
      ...(force_password_change && { force_password_change }),
    };
    yield put(setPlatformDetailsLoader(true));
    const resp = yield call(saveDoceboPlatformDetails, updatedPayload, userId);
    if (resp?.success) {
      const userDetailStore = yield select((state) => state.platformUserDetailsData);
      const { result } = userDetailStore;
      yield put(updateUserDetailsStore(
        {
          editDoceboDetails: false,
          isPlatformDetailsLoading: false,
          alertBarValues: {
            labelText: 'User platform details updated successfully.',
            variant: 'success',
            open: true,
          },
          result: { ...result, ...data, language: label },
        },
      ));
    } else {
      yield put(updateUserDetailsStore(
        {
          editDoceboDetails: false,
          isPlatformDetailsLoading: false,
          alertBarValues: {
            labelText: 'Update to user platform details failed. Please try again later.',
            variant: 'error',
            open: true,
          },
        },
      ));
    }
  } catch (err) {
    yield put(getUserDetailsFailure(err?.response?.data));
  }
}

export function* platformUserDetailsSaga() {
  yield takeLatest(FETCH_USER_DETAILS, getUserDetails);
  yield takeLatest(FETCH_LANGUAGES, getUserLanguages);
  yield takeLatest(SAVE_USER_DETAILS, saveUserDetailsSaga);
}
