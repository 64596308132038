import React, {
  useState, useEffect, useMemo, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _map from 'lodash/map';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import WarningIcon from '../../../assets/icons/alert-red-warning.svg';

import { MODERATE_DARK_GREY, ACCORDION_GREY, PALE_GREY } from '../../../stylesheets/colors';
import LabledTextField from '../../common/LabledTextField';
import LabeledView from '../../common/LabeledView';
import CustomSelect from '../../common/CustomSelect';
import ErrorMessage from '../../common/ErrorMessage';
import InfoTooltip from '../../common/InfoTooltip';
import EOPIcon from '../../../assets/icons/eop-icon.svg';
import { IMPORT_EOP_OPTIONS } from '../../../constants';
import CustomRadio from '../../UserEnrollment/common/CustomRadio';
import ErrorText from '../../common/ErrorText';
import LinkButton from '../../common/LinkButton';

const useStyles = makeStyles({
  configOptions: {
    color: MODERATE_DARK_GREY,
    fontSize: ' 1rem',
  },
  inputBox: {
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
  inputItem: {
    flex: '0 1 45%',
    width: '30rem',
  },
  labelText: {
    marginTop: '1rem',
  },
  lpDetailView: {
    padding: '2rem 1rem',
  },
  selectWrapper: {
    marginTop: '1rem',
  },
  selectLabelClass: {
    fontWeight: '300',
  },
  eopWrapper: {
    padding: '2rem 0',
  },
  eopLabelText: {
    padding: '1rem 0',
  },
  eopCourseWrapper: {
    padding: '2rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customSelectWrapper: {
    padding: '0.5rem 0rem',
    '& > p': { padding: '0.5rem 0rem' },
    '& > div > div.react-select__control': {
      height: 'auto',
      '& > div.react-select__value-container > div.react-select__single-value': {
        width: '100%',
      },
    },
  },
  radioGroup: {
    paddingTop: '1rem',
  },
  refreshWrapper: {
    display: 'flex',
    padding: '0.5rem 0rem',
  },
  disabledWrapper: {
    pointerEvents: 'none',
    opacity: 0.6,
    '& .MuiPaper-root': {
      backgroundColor: PALE_GREY,
    },
  },
  tooltipWrapper: {
    zIndex: '9999',
  },
});

const SelectLearningPlan = ({
  values, handleChange, isConfigLoading, debouncedChange, errors, touched,
  lpDetails, setFieldValue, onBlur, eopComponent, targetLpCode, setValues,
  onRefresh,
}) => {
  const classes = useStyles();
  const [componentOptions, setComponentOptions] = useState([]);
  const [sourceProgramId, setSourceProgramId] = useState('');
  const componentDropdownRef = useRef();
  const {
    configId, dropdownDefaultValue, componentName, componentId,
  } = values;

  const ClearDropdown = useCallback(() => {
    if (componentName && componentId) {
      setComponentOptions([]);
      setFieldValue('dropdownDefaultValue', {});
      setFieldValue('componentName', '', true);
      setFieldValue('componentId', '', true);
      setSourceProgramId('');
      if (componentDropdownRef.current) {
        componentDropdownRef.current.select.clearValue();
      }
    }
  }, [setFieldValue, componentName, componentId]);

  useEffect(() => {
    if (Object.keys(lpDetails).length) {
      const options = _map(lpDetails?.components, (obj) => ({
        // label: obj?.course_title,
        value: obj?.component_id,
        course: obj?.course_title,
        isEop: obj?.eop,
        code: obj?.code,
        doceboCourseId: obj?.docebo_course_id,
        label: (
          <Box style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0 1rem',
          }}
          >
            <Box style={{ width: '100%' }}>
              <Typography style={{ color: ACCORDION_GREY, textOverflow: 'ellipsis' }}>
                {obj?.course_title}
              </Typography>
              <Typography>{obj?.code}</Typography>
            </Box>
            {obj?.eop === true && (
              <InfoTooltip title="Feedback required at the end of this course" tooltipClasses={{ popper: classes.tooltipWrapper }} placement="top">
                <img src={EOPIcon} alt="eop" />
              </InfoTooltip>
            )}
          </Box>
        ),
      }));
      setComponentOptions(options);
      setSourceProgramId(lpDetails?.program_id);
    } else {
      ClearDropdown();
    }
  }, [lpDetails, classes.optionLabelWrapper, setFieldValue, ClearDropdown, classes.tooltipWrapper]);

  const getIcon = useMemo(() => {
    let icon = '';

    if (isConfigLoading) {
      icon = <CircularProgress size="2rem" />;
    } else if (lpDetails?.is_locked) {
      icon = <img src={WarningIcon} alt="ok-icon" />;
    } else if (lpDetails?.title) {
      icon = <img src={CheckRoundIcon} alt="ok-icon" />;
    }
    return icon;
  }, [
    lpDetails,
    isConfigLoading,
  ]);

  useEffect(() => {
    if (_has(dropdownDefaultValue, 'code')) {
      setValues({
        ...values,
        isHavingEOP: !_isEmpty(eopComponent) && dropdownDefaultValue?.isEop
          ? dropdownDefaultValue?.isEop : false,
        newComponentTitle: dropdownDefaultValue?.course,
        newComponentCode: dropdownDefaultValue?.code.endsWith(lpDetails?.config_id)
          ? dropdownDefaultValue?.code.replace(lpDetails?.config_id, targetLpCode)
          : `${dropdownDefaultValue?.code}_${targetLpCode}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownDefaultValue]);

  const beforeRefresh = (lpProgramId) => {
    ClearDropdown();
    onRefresh(lpProgramId);
  };

  return (
    <Box sx={{ width: '70%', margin: 'auto' }}>
      <Typography variant="subtitle1">
        Choose a learning plan to import a component copy from
      </Typography>
      <Box>
        <LabledTextField
          className={classes.labelText}
          name="configId"
          label="Configuration ID / Docebo Learning Plan ID"
          value={configId}
          required
          inputProps={{
            InputProps: {
              endAdornment: getIcon,
            },
          }}
          onBlur={onBlur}
          onChange={(e) => {
            handleChange(e);
            debouncedChange(e.target.value);
          }}
          error={!!errors.configId}
        />
        {errors.configId
          && (
            <ErrorMessage errorIcon errorText={errors.configId || 'No Learning Plan found with this ID. Please try another one.'} />
          )}
      </Box>

      <Paper variant="outlined" className={`${classes.lpDetailView} ${lpDetails?.is_locked && classes.disabledWrapper}`}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LabeledView label="LP Title:" value={lpDetails?.title} />
          </Grid>
          <Grid item xs={6}>
            <LabeledView label="No. of Components:" value={lpDetails?.components?.length} />
          </Grid>
          <Grid item xs={6}>
            <LabeledView label="Config ID:" value={lpDetails?.config_id} />
          </Grid>
          <Grid item xs={6}>
            <LabeledView label="Docebo ID:" value={lpDetails?.docebo_lp_id} />
          </Grid>
        </Grid>
      </Paper>
      {lpDetails?.is_locked
        && (
          <Typography>
            This Learning Plan is locked for copying components.
            Contact your admin to unlock or select another Learning Plan.
          </Typography>
        )}

      <Box className={`${classes.selectWrapper} ${lpDetails?.is_locked && classes.disabledWrapper}`}>
        <CustomSelect
          selectRef={componentDropdownRef}
          label="Select Component"
          defaultValue={dropdownDefaultValue}
          name="componentId"
          required
          labelClass={classes.selectLabelClass}
          options={componentOptions}
          className={classes.customSelectWrapper}
          onChange={(v) => {
            setFieldValue('dropdownDefaultValue', v);
            setFieldValue('componentName', v?.course, true);
            setFieldValue('componentId', v?.value, true);
            setFieldValue('doceboCourseId', v?.doceboCourseId);
          }}
          isOptionsNotLoading
        />
        {!_isEmpty(eopComponent) && (
          <Typography>
            {`${targetLpCode} already has an EOP component`}
          </Typography>
        )}
        {!eopComponent && dropdownDefaultValue?.isEop && (
          <Typography>
            You are choosing to import an EOP assigned component
          </Typography>
        )}
        {errors.componentId
          && <ErrorMessage errorText={errors.componentId || 'Select one component'} />}
        <Box className={classes.refreshWrapper}>
          {sourceProgramId && (
            <>
              <LinkButton text="Refresh" onClick={() => beforeRefresh(sourceProgramId)} />
              <Typography>the list incase you are unable to find a component</Typography>
            </>
          )}
        </Box>
      </Box>

      {!_isEmpty(eopComponent) && dropdownDefaultValue?.isEop && (
        <Box className={classes.eopWrapper}>
          <Typography variant="subtitle2" className={classes.eopLabelText}>
            The current Learning plan -
            {' '}
            {targetLpCode}
            {' '}
            already has an EOP assigned to the following component:
          </Typography>
          <Paper elevation="2" className={classes.eopCourseWrapper}>
            <Box className={classes.eopCourseText}>
              <Typography variant="subtitle2">{eopComponent?.component_title}</Typography>
              <Typography variant="subtitle2">{eopComponent?.code}</Typography>
            </Box>
            <InfoTooltip title="Feedback required at the end of this course" placement="top">
              <img src={EOPIcon} alt="eop" />
            </InfoTooltip>
          </Paper>
          <RadioGroup
            row
            className={classes.radioGroup}
            aria-label="channel"
            name="eopSetting"
            value={values.eopSetting}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {IMPORT_EOP_OPTIONS.map(
              ({
                label, value,
              }) => (
                <>
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<CustomRadio />}
                    label={label}
                  />
                </>
              ),
            )}
          </RadioGroup>
          <ErrorText touchedField={touched.eopSetting} errorField={errors.eopSetting} />
        </Box>
      )}
    </Box>

  );
};

SelectLearningPlan.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    configId: PropTypes.string.isRequired,
    dropdownDefaultValue: PropTypes.object.isRequired,
    eopSetting: PropTypes.string.isRequired,
    componentName: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
  }).isRequired,
  isConfigLoading: PropTypes.bool.isRequired,
  debouncedChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    configId: PropTypes.string,
    componentId: PropTypes.string,
    eopSetting: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    eopSetting: PropTypes.string,
  }).isRequired,
  lpDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    components: PropTypes.array.isRequired,
    config_id: PropTypes.string.isRequired,
    docebo_lp_id: PropTypes.string.isRequired,
    program_id: PropTypes.string.isRequired,
    is_locked: PropTypes.bool.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  eopComponent: PropTypes.object.isRequired,
  targetLpCode: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default SelectLearningPlan;
