import config from '../../config';
import request from '../../helpers/apiHelper';

export const getAllPrograms = async (programType, searchText, currentPage, stage, cancelToken) => {
  const url = `${config.ROUTES.PROGRAM}/${programType}?search=${encodeURIComponent(
    searchText,
  )}&page=${currentPage}&stage=${stage}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options);

  return result;
};

export const getAllFilteredPrograms = async (page, data = {}) => {
  const url = `${config.ROUTES.PROGRAM}/filter?page=${page}`;
  const options = {
    url, method: 'POST', data,
  };
  const result = await request(options);

  return result;
};

export const getProgramsBySearch = async (searchText = '', parentProgramIds = '') => {
  const url = `${config.ROUTES.PROGRAM}/search?search=${encodeURIComponent(
    searchText,
  )}&parent_program_ids=${parentProgramIds}`;
  const options = { url, method: 'GET' };
  const result = await request(options);

  return result;
};

export const logoutUser = async () => {
  const url = `${config.ROUTES.LOGOUT}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options);

  return result;
};

export const getEnvironment = async () => {
  const url = `${config.ROUTES.ENVIRONMENT} `;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const getLtiToken = async () => {
  const url = `${config.ROUTES.LTI_TOKEN} `;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const saveFilterGroup = async (data) => {
  const url = `${config.ROUTES.PROGRAM}/filter_groups/create`;
  const options = {
    url, method: 'POST', data,
  };
  const result = await request(options);

  return result;
};

export const updateFilterGroup = async (filterGroupId, data) => {
  const url = `${config.ROUTES.PROGRAM}/filter_groups/${filterGroupId}/update`;
  const options = {
    url, method: 'PUT', data,
  };
  const result = await request(options);

  return result;
};

export const getFilterGroups = async () => {
  const url = `${config.ROUTES.PROGRAM}/filter_groups/list?page_size=100`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const deleteFilterGroups = async (filterId) => {
  const url = `${config.ROUTES.PROGRAM}/filter_groups/${filterId}/delete`;

  const options = { url, method: 'DELETE' };
  const result = await request(options);
  return result.data;
};
