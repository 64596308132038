import {
  AUTO_ENROLLMENT,
  GROUPWORK_GROUPS,
  WEBINAR_MANAGEMENT,
  ASSIGNMENT_MANAGEMENT,
  WORKSHOP_MANAGEMENT, GROUPWORK_MANAGEMENT, GROUP_ENROLLMENT, WEBINAR,
} from '../constants';

const routerBase = () => {
  const path = new URL(document.baseURI).pathname;
  return path || '/';
};

let { API_SERVER, REPLICATION_SERVER, LTI_SERVER } = window;
let GATEWAY_SERVER = API_SERVER;

if (process.env.REACT_APP_IS_LOCAL_ENV === 'true') {
  API_SERVER = 'http://localhost:3000/api';
  GATEWAY_SERVER = 'http://localhost:3000';
  REPLICATION_SERVER = 'http://localhost:3000/replication';
  LTI_SERVER = 'https://lti-local.com';
}

export default {
  API_SERVER,
  GATEWAY_SERVER,
  BASE_PATH: routerBase(),
  // ENABLE_COOKIE_AUTH: window.ENABLE_COOKIE_AUTH === 'true',
  ROUTES: {
    // User, auth and env
    USER_DETAILS: `${API_SERVER}/user`,
    ENVIRONMENT: `${API_SERVER}/environment`,
    LTI_TOKEN: `${API_SERVER}/lti/journal/token`,

    // Sidebar
    PROGRAM: `${API_SERVER}/program`,

    // Platform logs
    PLATFORM_LOGS: `${API_SERVER}/platform/logs`,

    // LTI
    JOURNALS: `${LTI_SERVER}/journal/api`,

    // XLSX
    AUTOMATION: `${API_SERVER}/automation`,
    DOWNLOAD_XLSX: `${API_SERVER}/report/xlsx`,
    DOWNLOAD_REPLICATION_XLSX: `${API_SERVER}/replication/report/xlsx`,
    REUPLOAD_XLSX: `${API_SERVER}/reupload`,
    VALIDATE_XLSX: `${API_SERVER}/dat/validate`,

    // Form types
    GW_GROUPS: `${API_SERVER}/automation/${GROUPWORK_GROUPS}`,
    GROUP_ENROLLMENT: `${API_SERVER}/automation/${GROUP_ENROLLMENT}`,
    GW: `${API_SERVER}/dat/${GROUPWORK_GROUPS}`,
    GW2: `${API_SERVER}/automation/${GROUPWORK_GROUPS}`,
    USER_ENROLLMENT: `${API_SERVER}/automation/${AUTO_ENROLLMENT}`,
    VALIDATE_JSON: `${API_SERVER}/dat/validate_json`,

    // Progress
    GET_PROGRESS: `${API_SERVER}/progress`,

    // Report
    GET_REPORT: `${API_SERVER}/report`,
    PROGRAM_LINKS: `${API_SERVER}/program_links`,
    MARK_FOR_COMPLETION: `${API_SERVER}/completion`,
    SERVICE: `${API_SERVER}/service`,

    // Overview page
    OVERVIEW_CONTENT: `${API_SERVER}/overview/content`,
    OVERVIEW_FOLDER_CONTENT: `${API_SERVER}/overview/folder_contents`,
    OVERVIEW_COMPONENT: `${API_SERVER}/overview/component`,
    OVERVIEW_COMPONENTS: `${API_SERVER}/overview/components`,
    OVERVIEW_COMPONENTS_LP_COUNT: `${API_SERVER}/overview/components/enrollment_count/lp`,
    OVERVIEW_COMPONENTS_COURSE_COUNT: `${API_SERVER}/overview/components/enrollment_count/course`,
    OVERVIEW_USERS_COHORTS: `${API_SERVER}/overview/users/cohorts`,
    OVERVIEW_USERS_ACTIVE: `${API_SERVER}/overview/users/active`,
    OVERVIEW_USERS_ENROLLED: `${API_SERVER}/overview/users/enrolled`,
    OVERVIEW_DOWNLOAD: `${API_SERVER}/overview/xlsx`,

    // User field
    GET_USER_FIELD: `${API_SERVER}/user_field`,

    // Extended Enterprise
    GET_EXTENDED_ENTERPRISE: `${API_SERVER}/dat/extended_enterprise`,

    // NVT User info
    NVT_USER_INFO: `${API_SERVER}/user`,
    USERS_API: `${API_SERVER}/users`,

    // Report content
    GET_ELUCIDAT_COURSE_CONTENT: `${API_SERVER}/course_content/elucidat`,
    GET_DOCEBO_COURSE_CONTENT: `${API_SERVER}/course_content/docebo`,
    GET_COURSE_INFO: `${API_SERVER}/course_content`,
    POST_COURSE_INFO: `${API_SERVER}/course_content`,

    // Cancel transaction,
    CANCEL_TRANSACTION: `${API_SERVER}/cancel_transaction`,

    // Webinar
    GET_WEBINARS: `${API_SERVER}/automation/${WEBINAR_MANAGEMENT}`,
    GET_WEBINAR_ENROLLMENTS: `${API_SERVER}/enrollments/webinar`,

    // Replication
    REPLICATION_SCAN: `${REPLICATION_SERVER}/docebo`,
    REPLICATION_PROGRESS: `${REPLICATION_SERVER}/progress`,
    REPLICATION_DOWNLOAD_XLSX: `${REPLICATION_SERVER}/report/xlsx`,
    REPLICATION_ELUCIDAT_CHECK: `${REPLICATION_SERVER}/elucidat/validate_project_id`,
    REPLICATION_CID_CHECK: `${REPLICATION_SERVER}/dat/unique_config_id`,
    REPLICATION_CONFIRM: `${REPLICATION_SERVER}/dat/replicate`,
    REPLICATION: `${REPLICATION_SERVER}/dat/replication`,
    REPLICATION_ELUCIDAT: `${REPLICATION_SERVER}/elucidat`,

    // Edit Program
    EDIT_PROGRAM_TRANSACTION: `${API_SERVER}/dat/edit_program_code`,
    EDIT_PROGRAM_ID: `${API_SERVER}/automation/edit_program_code`,
    EDIT_PROGRAM_TIMELINE: `${API_SERVER}/dat/edit_program_code`,
    EDIT_PROGRAM_CID_CHECK: `${API_SERVER}/dat/unique_config_id`,
    EDIT_PROGRAM_DELETE_TRANSACTION: `${API_SERVER}/dat/edit_program_code`,

    // User enrollment
    USER_ENROLLMENT_SEARCH: `${API_SERVER}/dat/${AUTO_ENROLLMENT}/branches`,

    // Assignment Management
    ASSIGNMENT_MANAGEMENT: `${API_SERVER}/${ASSIGNMENT_MANAGEMENT}`,

    // Groupwork Management
    GROUPWORK_MANAGEMENT: `${API_SERVER}/${GROUPWORK_MANAGEMENT}`,

    // Metadata Management
    METADATA_MANAGEMENT: `${API_SERVER}/metadata`,

    // Settings
    LP_SETTINGS: `${API_SERVER}/settings/lp`,

    // Workshop Management
    GET_WORKSHOPS: `${API_SERVER}/lp/${WORKSHOP_MANAGEMENT}`,
    GET_WORKSHOP_EVENTS: `${API_SERVER}/lp/${WORKSHOP_MANAGEMENT}/session`,
    WORKSHOP_SESSION: `${API_SERVER}/${WORKSHOP_MANAGEMENT}`,
    WEBINAR: `${API_SERVER}/${WEBINAR}`,
    EDIT_EVENT: `${API_SERVER}/lp/${WORKSHOP_MANAGEMENT}`,
    VENUES: `${API_SERVER}/dat/${WORKSHOP_MANAGEMENT}/venues`,

    // Content Management
    CONTENT_MANAGEMENT: `${API_SERVER}/content_management`,
    PRODUCTION_CHECKLIST: `${API_SERVER}/production_checklist`,

    // UPLOAD image to
    UPLOAD_IMAGE: `${API_SERVER}/settings/lp/image/upload`,

    // Bulk Wave Management
    WAVE_MANAGEMENT: `${API_SERVER}/wave_management`,
    GET_WAVES: `${API_SERVER}/wave_management/waves`,
    COMPONENTS_DUE_DATE: `${API_SERVER}/wave_management/wave/components`,
    DELETE_WAVES: `${API_SERVER}/wave_management/wave`,
    TRANSFER_USERS_WAVE: `${API_SERVER}/wave_management/transfer_users`,
    ENROLLMENT_METADATA: `${API_SERVER}/wave_management/update_users`,
    UNTAGGED_USERS: `${API_SERVER}/wave_management/wave/untagged_users`,
    MANAGE_COHORT: `${API_SERVER}/wave_management/manage_cohort`,
    COHORT_USER_PASSWORD_UPDATE: `${API_SERVER}/wave_management/bulk_password_update`,

    // Master data
    MASTER_DATA_CLIENT: `${API_SERVER}/master/client`,
    MASTER_DATA_CLIENTS: `${API_SERVER}/master/clients`,
    MASTER_DATA_PORTFOLIO: `${API_SERVER}/master/portfolio`,
    MASTER_DATA_CLIENT_ID: `${API_SERVER}/master/client_id`,
    MASTER_DATA_SKILL: `${API_SERVER}/master/skills`,

    // Platform Level Users
    USER_MANAGEMENT: `${API_SERVER}/user_management/users`,
    USER_MANAGEMENT_USERS: `${API_SERVER}/user_management/filter/users`,
    USER_MANAGEMENT_USER_DETAILS: `${API_SERVER}/user_management`,
    USER_MANAGEMENT_USER_DATA: `${API_SERVER}/user_management/user`,

    GET_TRANSACTION: `${API_SERVER}/user_profile/transactions?program_sub_type=`,
  },
};
