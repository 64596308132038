import {
  FILE_VALIDATE, FILE_VALIDATE_FAILURE, FILE_VALIDATE_SUCCESS, CLEAR_MESSAGE,
} from '../constants';

const initialState = {
  isLoading: false,
  errors: [],
  message: '',
};

function updateScreenState(state) {
  return {
    ...state, isLoading: true, errors: [], message: '',
  };
}

function onFileValidateSuccess(state, { results }) {
  if (results.errors) {
    return { ...state, isLoading: false, errors: results.errors };
  }
  return { ...state, isLoading: false, message: results.message };
}

function onFileUploadFailure(state, { results }) {
  return { ...state, isLoading: false, results };
}

export default function FileValidateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILE_VALIDATE:
      return updateScreenState(state, action);
    case FILE_VALIDATE_SUCCESS:
      return onFileValidateSuccess(state, action);
    case FILE_VALIDATE_FAILURE:
      return onFileUploadFailure(state, action);
    case CLEAR_MESSAGE:
      return { ...state, message: '', errors: [] };
    default:
      return state;
  }
}
